<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="update" method="POST">
          <div class="row">
            <div class="col-md-12">
              <h4 class="mb-4">
                Perfil de Empresa
                <div
                  v-if="
                    companyData.status && companyData.status.name == 'approved'
                  "
                  class="flex-row-reverse float-right ml-md-auto align-self-md-end pl-1"
                >
                  <a
                    v-if="companyData.user.active"
                    href="javascript:void(0);"
                    :attr_id="companyData.user.id"
                    class="btn btn-sm bg-danger-light action-disable"
                  >
                    <i class="fas fa-times"></i> Desactivar
                  </a>
                  <a
                    v-else
                    href="javascript:void(0);"
                    :attr_id="companyData.user.id"
                    class="btn btn-sm bg-success-light action-enable"
                  >
                    <i class="fas fa-check"></i> Activar
                  </a>
                </div>
              </h4>
            </div>
          </div>

          <!-- Basic Information -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                Datos de la cuenta
                <span
                  v-if="companyData.user && companyData.user.active"
                  class="badge light badge-success text-xs"
                  >Activo</span
                >
                <span v-else class="badge light badge-dark text-xs"
                  >Desactivado</span
                >
              </h4>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Usuario</label>
                    <input
                      v-lower-case
                      v-model="companyData.user.username"
                      type="text"
                      class="form-control"
                      readonly
                    />
                    <small v-if="errors.username" class="text-danger">{{
                      errors.username[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      v-lower-case
                      v-model="companyData.user.email"
                      type="email"
                      class="form-control"
                      readonly
                    />
                    <small v-if="errors.email" class="text-danger">{{
                      errors.email[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Nombre</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="companyData.user.names"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.names" class="text-danger">{{
                      errors.names[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Apellido</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="companyData.user.surnames"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.surnames" class="text-danger">{{
                      errors.surnames[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label>
                      Foto de Perfil
                      <span class="text-danger">*</span>
                    </label>
                    <div class="change-avatar">
                      <div class="profile-img">
                        <a
                          download="foto-empresa"
                          :href="documentForLink"
                        >
                          <v-img
                            eager
                            max-height="100"
                            max-width="100"
                            height="100"
                            alt="Documento de Identidad"
                            width="100"
                            :src="companyData.document"
                          ></v-img>
                        </a>
                      </div>
                      <div class="upload-img">
                        <div class="change-photo-btn">
                          <span> <i class="fa fa-upload"></i> Subir Foto </span>
                          <v-file-input
                            ref="uploadDocument"
                            @click:clear="clearDocumentImage"
                            @change="uploadDocumentImage"
                            class="upload"
                            dense
                            show-size
                            small-chips
                            :accept="allowedMimeTypesAsString"
                            :rules="fileRules"
                            label="Subir Foto"
                          ></v-file-input>
                        </div>
                        <small class="form-text text-muted"
                          >Permitido JPG o PNG.</small
                        >
                      </div>
                    </div>
                    <small v-if="errors.document" class="text-danger">{{
                      errors.document[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Estado</label>
                    <span class="text-danger">*</span>
                    <v-autocomplete
                      class="form-control"
                      :items="ClientStatuses"
                      flat
                      hide-details
                      hide-no-data
                      solo-inverted
                      item-text="display_name"
                      item-value="id"
                      v-model="companyData.status_id"
                    ></v-autocomplete>
                    <small v-if="errors.status_id" class="text-danger">{{
                      errors.status_id[0]
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Basic Information -->

          <!-- Company Details -->
          <div class="card contact-card">
            <div class="card-body">
              <h4 class="card-title">Detalles de la empresa</h4>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Razón Social</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="companyData.social_reason"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.social_reason" class="text-danger">{{
                      errors.social_reason[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Número de Identificación Fiscal</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="companyData.nif"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.nif" class="text-danger">{{
                      errors.nif[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Teléfono de la empresa</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="companyData.phone"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.phone" class="text-danger">{{
                      errors.phone[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Dirección</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="companyData.address.address"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.address" class="text-danger">{{
                      errors.address[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Ciudad</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="companyData.address.city"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.city" class="text-danger">{{
                      errors.city[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Estado / Provincia</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="companyData.address.state"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.state" class="text-danger">{{
                      errors.state[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">País</label>
                    <span class="text-danger">*</span>
                    <select
                      v-model="companyData.address.country_id"
                      class="custom-select"
                    >
                      <option
                        v-for="(value, key) in countries"
                        v-bind:key="key"
                        :value="value.id"
                        >{{ value.name }}</option
                      >
                    </select>
                    <small v-if="errors.country_id" class="text-danger">{{
                      errors.country_id[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Código Postal</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="companyData.address.zip"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.zip" class="text-danger">{{
                      errors.zip[0]
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Company Details -->

          <!-- Social Network -->
          <div class="card contact-card">
            <div class="card-body">
              <h4 class="card-title">Redes Sociales</h4>
              <div class="row form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Página web</label>
                    <input
                      v-model="companyData.web_site"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.web_site" class="text-danger">{{
                      errors.web_site[0]
                    }}</small>
                    <small v-else class="form-text text-muted"
                      >https://www.ejemplo.com</small
                    >
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Facebook</label>
                    <input
                      v-model="companyData.facebook"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.facebook" class="text-danger">{{
                      errors.facebook[0]
                    }}</small>
                    <small v-else class="form-text text-muted"
                      >Cuenta de Facebook</small
                    >
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Instagram</label>
                    <input
                      v-model="companyData.instagram"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.instagram" class="text-danger">{{
                      errors.instagram[0]
                    }}</small>
                    <small v-else class="form-text text-muted"
                      >Cuenta de Instagram</small
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Contact Details -->

          <div class="submit-section submit-btn-bottom">
            <button type="submit" class="btn btn-primary">
              Actualizar
            </button>
            <button
              type="button"
              class="btn btn-secondary ml-1"
              @click="routeToClients()"
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import image from '../../helpers/image';

export default {
  data() {
    return {
      companyData: {
        social_reason: "",
        nif: "",
        phone: "",
        web_site: "",
        facebook: "",
        instagram: "",
        user: {
          active: 0,
          names: "",
          surnames: "",
          username: "",
          email: "",
          password: "",
          password_confirmation: "",
        },
        address: {
          address: "",
          city: "",
          zip: "",
          country_id: "",
        },
        document: "",
        document_name: null,
        accept_terms: false,
      },
      currentDocumentImage: null,
      loadedDocumentImage: false,
      isDirtyDocumentImage: false,
      fileRules: [],
      countries: [],
      client_statuses: [],
      errors: [],
    };
  },
  mixins: [global, image],
  beforeMount() {
    this.getCountries();
    this.getCompany();
  },
  mounted() {
    this.fileRules = [
      this.maxSizeRule(),
      this.mimeTypeRule(),
    ];
  },
  methods: {
    routeToClients: function() {
      localStorage.setItem("clientsActiveTab", "#company");
      this.$router.push("/clients");
    },
    getCompany: function() {
      axios
      .get(
        process.env.VUE_APP_API_URL +
          "/api/v1/profiles/company/get/" +
          this.$route.params.id
      )
      .then((response) => {
        //console.log(response.data.company);
        response.data.company.document = "";
        this.companyData = response.data.company;

        this.getDocumentImage();
        //console.log(response.data.message);
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];

        EventBus.$emit("showErrors", {
          msg: error.response.data.error,
        });

        console.log(error.response.data.error);
      })
      .finally(() => {
        var _self = this;

        $("form").on("click", ".action-enable", function() {
          _self.enable(_self.$route.params.id);
        });

        $("form").on("click", ".action-disable", function() {
          _self.disable(_self.$route.params.id);
        });
      });
    },
    getDocumentImage: function() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/clients/company/document/" +
            this.$route.params.id,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          if (response.data.byteLength > 0) {
            this.companyData.document =
              "data:" +
              response.headers["content-type"] +
              ";base64," +
              Buffer.from(response.data, "binary").toString("base64");
          }
        })
        .catch(() => {});
    },
    uploadDocumentImage(file) {
      this.clearDocumentErrors();

      if (file == null ||
          !file ||
          file.size > process.env.VUE_APP_IMAGE_FILE_MAX_SIZE ||
          !this.checkMimeTypeRule(file.type)
      ) {
        this.loadedDocumentImage = false;
        this.clearDocumentImage();
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.companyData.document = reader.result;
        this.loadedDocumentImage = true;
      };
    },
    enable(id) {
      axios
        .get(
          process.env.VUE_APP_API_URL + "/api/v1/clients/company/enable/" + id
        )
        .then((response) => {
          this.companyData.user.active = 1;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    disable(id) {
      axios
        .get(
          process.env.VUE_APP_API_URL + "/api/v1/clients/company/disable/" + id
        )
        .then((response) => {
          this.companyData.user.active = 0;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    update: function() {
      if (!this.loadedDocumentImage && !this.$refs.uploadDocument.validate()) {
        this.errors = [];

        return;
      }

      let request = { ...this.companyData };

      if (!this.loadedDocumentImage && !this.isDirtyDocumentImage) {
        this.$refs.uploadDocument.resetValidation();
        request.document = '';
      }

      if (!this.isDirtyDocumentImage && !this.loadedDocumentImage && this.hasDocumentImage) {
        this.$refs.uploadDocument.resetValidation();
        delete request.document;
      }

      axios
        .put(
          process.env.VUE_APP_API_URL +
            "/api/v1/clients/company/update-by-admin/" +
            this.$route.params.id,
            request
        )
        .then((response) => {
          this.companyData = response.data.company;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/clients");
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    clearDocumentImage() {
      this.companyData.document = this.currentDocumentImage;
      this.clearDocumentErrors();
    },
    clearDocumentErrors() {
       this.errors.document = [];
    },

  },
  computed: {
    documentForLink() {
      return this.companyData.document ?? 'javascript:void()';
    },
    hasDocumentImage() {
      return !!this.companyData.document_name;
    }
  },
  watch: {
    'companyData.document': function (val) {
      if (val && !this.currentDocumentImage) {
        this.currentDocumentImage = val;
      }

      if (this.companyData.document_name) {
        this.isDirtyDocumentImage = !_.isEqual(val, this.currentDocumentImage);
      }
    }
  }
};
</script>
