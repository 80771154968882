<template>
  <layout-login>
    <div class="login-page">
      <div class="main-wrapper">
        <!-- Page Content -->
        <div class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-8 offset-md-2">
                <!-- Login Tab Content -->
                <div class="login-content">
                  <div class="row min-vh-100">
                    <div
                      class="col-md-12 col-lg-6 login-box align-self-center mx-auto"
                    >
                      <div class="login-header">
                        <h3>Inicio de sesión</h3>
                      </div>
                      <form @submit.prevent="auth">
                        <div
                          class="form-group form-focus"
                          :class="{ focused: focused }"
                        >
                          <input
                            v-model="username"
                            type="text"
                            class="form-control floating"
                            placeholder=" "
                            required
                            @focus="focused = true"
                            @blur="focused = false"
                          />
                          <small v-if="errors.username" class="text-danger">{{
                            errors.username[0]
                          }}</small>
                          <label class="focus-label">Usuario</label>
                        </div>
                        <div
                          class="form-group form-focus"
                          :class="{ focused: focused }"
                        >
                          <input
                            v-model="password"
                            type="password"
                            class="form-control floating"
                            placeholder=" "
                            required
                            @focus="focused = true"
                            @blur="focused = false"
                          />
                          <small v-if="errors.password" class="text-danger">{{
                            errors.password[0]
                          }}</small>
                          <label class="focus-label">Contraseña</label>
                        </div>
                        <button
                          class="btn btn-primary btn-block btn-lg login-btn"
                          type="submit"
                        >
                          Entrar
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <!-- /Login Tab Content -->
              </div>
            </div>
          </div>
        </div>
        <!-- /Page Content -->
      </div>
    </div>
  </layout-login>
</template>

<script>
import LayoutLogin from "@/views/Login.vue";
import auth from "@/helpers/auth";
import { EventBus } from "@/main";

export default {
  components: {
    LayoutLogin,
  },
  data() {
    return {
      focused: false,
      username: "",
      password: "",
      errors: [],
    };
  },
  mounted() {
    const access_token = localStorage.getItem("access_token") || "";
    const created_at = parseInt(localStorage.getItem("created_at")) || "";
    const expires_in = parseInt(localStorage.getItem("expires_in")) || "";
    const now = new Date();
    if (
      auth.getters.isLoggedIn &&
      access_token &&
      created_at &&
      expires_in &&
      new Date(
        new Date(created_at).setSeconds(
          new Date(created_at).getSeconds() + expires_in
        )
      ) > now
    ) {
      this.$router.push("/");
    }
  },
  methods: {
    auth() {
      let username = this.username;
      let password = this.password;
      auth
        .dispatch("login", {
          username,
          password,
        })
        .then(() => this.$router.push("/"))
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error);
        });
    },
  },
};
</script>
