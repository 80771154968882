<template>
  <layout-dashboard>
    <!-- Page Content -->
    <div class="content pt-0">
      <div class="row">
        <div class="col-12">
          <div class="invoice-content">
            <div class="invoice-item">
              <div class="row">
                <div class="col-md-6">
                  <h4>Detalles del retiro</h4>
                </div>
                <div class="col-md-6">
                  <p class="invoice-details">
                    <strong>Orden:</strong> #{{ withdrawalData.id }}<br />
                    <strong>Fecha:</strong> {{ withdrawalData.date }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Invoice Item -->
            <div class="invoice-item">
              <div class="row">
                <div class="col-md-4">
                  <div class="invoice-info">
                    <strong class="customer-text">Beneficiario</strong>
                    <p v-if="person !== null" class="invoice-details invoice-details-two">
                      {{ person.user.full_name }} ({{ person.user.username }})<br />
                      {{ person.address.address }}, {{ person.address.city }}<br />
                      {{ person.address.state }}, {{ person.address.country.name }} <br /><br />
                      Email: {{ person.user.email }}
                    </p>
                    <p v-if="company !== null" class="invoice-details invoice-details-two">
                      {{ company.user.full_name }} ({{ company.user.username }})<br />
                      {{ company.address.address }}, {{ company.address.city }}<br />
                      {{ company.address.state }}, {{ company.address.country.name }} <br /><br />
                      Email: {{ company.user.email }}
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="invoice-info">
                    <strong class="customer-text">Método de pago</strong>
                    <p v-if="withdrawalData.withdrawal_way" class="invoice-details invoice-details-two">
                      {{ withdrawalData.withdrawal_way.name }}<br />
                      <span v-for="(field, key) in withdrawalData.metadata" v-bind:key="key">
                        <label class="font-weight-bold">{{ field.label }}:</label>
                        {{ field.pivot.value }}<br />
                      </span>
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="invoice-info">
                    <strong class="customer-text">Datos en Billetera</strong>
                    <p class="invoice-details invoice-details-two">
                      <span>
                        <label class="font-weight-bold">Monto:</label>
                        {{ formatCurrency( parseFloat(person.wallet.amount) + parseFloat(withdrawalData.total_amount)) }}<br />
                      </span>
                      <span class="badge-pending">
                        <label class="font-weight-bold">Pendiente: </label>
                        {{ formatCurrency(person.wallet.recharge_amount_pending) }}<br />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Invoice Item -->

            <!-- Invoice Item -->
            <div class="invoice-item invoice-table-wrap">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table v-if="withdrawalData.id" class="invoice-table table table-bordered">
                      <thead>
                        <tr>
                          <th>Descripción</th>
                          <th class="text-center">Moneda</th>
                          <th class="text-right">Monto</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Solicitud de retiro</td>
                          <td class="text-center">
                            {{ withdrawalData.currency.acronym }}
                          </td>
                          <td class="text-right">
                            {{ amountWithOutSign }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-lg-8 col-xl-7 ml-auto pt-0">
                  <div class="table-responsive">
                    <table class="invoice-table-two table">
                      <tbody>
                        <tr>
                          <th rowspan="2" class="align-middle pt-0">
                            Comisiones
                          </th>
                          <th class="pt-0">Método de Pago:</th>
                          <td class="pt-0">
                            <span
                              ><span v-if="withdrawalData.withdrawal_way_commission > 0">+</span
                              >{{ formatCurrency(withdrawalData.withdrawal_way_commission) }}</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <th>Aless Pay:</th>
                          <td>
                            <span
                              ><span v-if="withdrawalData.system_commission > 0">+</span
                              >{{ formatCurrency(withdrawalData.system_commission) }}</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <th></th>
                          <th class="py-5">Monto a descontar:</th>
                          <td class="py-5">
                            <span>{{ amountTransactionWithOutSign }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Invoice Item -->

            <!-- Invoice Information -->
            <div class="other-info">
              <div class="form-group mr-2">
                <label class="col-form-label mr-2 font-weight-bold">Estado:</label>
                <span class="mr-2">{{ withdrawalData.status.display_name }}</span>
                <button
                  v-if="withdrawalData.status.name == 'pending' && person.wallet.amount >= 0"
                  type="button"
                  class="btn btn-primary mr-sm-1"
                  data-toggle="modal"
                  data-target="#modalComplete"
                >
                  <i class="fas fa-check"></i> Completar
                </button>
                <button
                  v-if="withdrawalData.status.name == 'pending'"
                  type="button"
                  class="btn btn-danger mr-sm-1"
                  data-toggle="modal"
                  data-target="#modalCancel"
                >
                  <i class="fas fa-times"></i> Rechazar
                </button>
                <button
                  v-if="withdrawalData.status.name == 'canceled'"
                  type="button"
                  @click="setToPending"
                  class="btn btn-warning mr-sm-1"
                >
                  <i class="fas fa-undo"></i> Volver a procesar
                </button>
              </div>
            </div>
            <!-- /Invoice Information -->

            <!-- Modal Complete -->
            <div
              class="modal fade complete"
              id="modalComplete"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modalCompleteLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-md modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header border-0 pb-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">
                        <i class="far fa-times-circle"></i>
                      </span>
                    </button>
                  </div>
                  <form @submit.prevent="complete" method="POST">
                    <div class="modal-body pt-0">
                      <label class="mb-4">Adjuntar fichero</label>
                      <div class="form-group mb-0">
                        <div class="change-avatar">
                          <div class="profile-img">
                            <a v-if="completeData.file" download="fichero" :href="completeData.file">
                              <img :src="completeData.file" alt="Fichero" />
                            </a>
                            <a v-else download="fichero" href="javascript:void()">
                              <img src="@/assets/img/profile.jpg" alt="Fichero" />
                            </a>
                          </div>
                          <div class="upload-img">
                            <div class="change-photo-btn">
                              <span> <i class="fa fa-upload"></i> Seleccionar </span>
                              <v-file-input
                                @change="uploadDocument"
                                class="upload drag-drop"
                                dense
                                show-size
                                small-chips
                                :accept="this.allAllowedMimeTypesAsString"
                                :rules="fileRules"
                                label="Subir Foto"
                              ></v-file-input>
                            </div>
                            <small class="form-text text-muted">Permitido PDF, JPG o PNG.</small>
                          </div>
                        </div>
                        <small v-if="errors.file" class="text-danger">{{ errors.file[0] }}</small>
                      </div>
                    </div>
                    <div class="modal-footer border-0 justify-content-end pt-0">
                      <button type="submit" class="btn btn-primary"><i class="fas fa-times"></i> Finalizar</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- /Modal Complete -->

            <!-- Modal Deny -->
            <div
              class="modal fade deny"
              id="modalCancel"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modalCancelLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header border-0 pb-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">
                        <i class="far fa-times-circle"></i>
                      </span>
                    </button>
                  </div>
                  <form @submit.prevent="cancel" method="POST">
                    <div class="modal-body">
                      <label class="required">Por favor, entre una razón para rechazar la orden.</label>
                      <textarea class="form-control" rows="8" v-model="deny.reason"></textarea>
                      <span class="text-danger" v-if="errors.reason">{{ errors.reason[0] }}</span>
                    </div>
                    <div class="modal-footer border-0 justify-content-end">
                      <button type="submit" class="btn btn-danger"><i class="fas fa-times"></i> Rechazar</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- /Modal Deny -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import image from "../../helpers/image";

export default {
  data() {
    return {
      withdrawalData: {
        id: "",
        date: "",
        status: "",
        total_amount: "0.00",
      },
      deny: {
        reason: "",
      },
      completeData: {
        file: "",
      },
      person: null,
      company: null,
      fileRules: [],
      errors: [],
      errors_upload: [],
      success_upload: [],
    };
  },
  mixins: [global, image],
  beforeMount() {
    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/requests/withdrawal/find/" + this.$route.params.id)
      .then((response) => {
        this.withdrawalData = response.data.withdrawalRequest;
        this.withdrawalData.total_amount =
          parseFloat(this.withdrawalData.amount) +
          parseFloat(this.withdrawalData.system_commission) +
          parseFloat(this.withdrawalData.withdrawal_way_commission);
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];

        EventBus.$emit("showErrors", {
          msg: error.response.data.error,
        });

        console.log(error.response.data.error);
      })
      .finally(() => {
        if (this.withdrawalData.person_id) {
          this.setPerson();
        } else if (this.withdrawalData.company_id) {
          this.setCompany();
        }
      });
  },
  mounted() {
    this.fileRules = [this.maxSizeRule(), this.mimeTypeRule(true)];
  },
  methods: {
    setPerson() {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/profiles/person/get/" + this.withdrawalData.person_id)
        .then((response) => {
          this.person = response.data.person;
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    setCompany() {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/profiles/company/get/" + this.withdrawalData.company_id)
        .then((response) => {
          this.company = response.data.company;
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    uploadDocument(file) {
      if (
        file == null ||
        !file ||
        file.size > process.env.VUE_APP_IMAGE_FILE_MAX_SIZE ||
        !this.checkMimeTypeRule(file.type, true)
      ) {
        this.completeData.file = "";
        return;
      }

      this.errors = [];

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.completeData.file = reader.result;
      };
    },
    complete() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/v1/requests/withdrawal/complete/" + this.withdrawalData.id,
          this.completeData
        )
        .then((response) => {
          //There is no endpoint to get status
          this.withdrawalData.status.name = "completed";
          this.withdrawalData.status.display_name = "Completada";

          $("#modalComplete").modal("hide");

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/withdrawal");
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          $("#modalComplete").modal("hide");

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    cancel() {
      axios
        .post(process.env.VUE_APP_API_URL + "/api/v1/requests/withdrawal/cancel/" + this.withdrawalData.id, this.deny)
        .then((response) => {
          //There is no endpoint to get status
          this.withdrawalData.status.name = "canceled";
          this.withdrawalData.status.display_name = "Cancelada";

          $("#modalCancel").modal("hide");

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/withdrawal");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          $("#modalCancel").modal("hide");

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    setToPending() {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/requests/withdrawal/set-to-pending/" + this.withdrawalData.id)
        .then((response) => {
          //There is no endpoint to get status
          this.withdrawalData.status.name = "pending";
          this.withdrawalData.status.display_name = "Pendiente";

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/withdrawal");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
  },
  computed: {
    amountWithOutSign() {
      return _.get(this.withdrawalData, "amount_display", "").replace("-", "");
    },
    amountTransactionWithOutSign() {
      return _.get(this.withdrawalData, "amount_transaction_display", "").replace("-", "");
    },
  },
};
</script>

<style lang="css" scoped>
.badge-pending {
  color: #ff8f16;
}
</style>
