<template>
  <layout-dashboard>
    <banner-form
        :bannerData.sync="bannerData"
        ref="editBannerForm"
        @submit="onSubmit"
    ></banner-form>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import BannerForm from './BannerForm';

export default {
  name: "EditBannerForm",
  components: { BannerForm },
  mixins: [global],
  data: () => ({
    bannerData: {},
  }),
  methods:{
    async getBannerFromRouteParam() {
      const getBannerUrl = `${process.env.VUE_APP_API_URL}/api/v1/banners/get/${this.$route.params.slug}`;

      const { data: { banner } } = await axios.get(getBannerUrl);

      this.bannerData = { ...banner };
    },
    async update() {
      const updateBannerUrl = `${process.env.VUE_APP_API_URL}/api/v1/banners/update/${this.bannerData.slug}`;

      try {
        const { data: { banner: banner, message } } = await axios.put(updateBannerUrl, this.bannerData);

        this.$refs.editBannerForm.stopLoading();
        this.$refs.editBannerForm.clear();

        if (banner.slug && message) {
          EventBus.$emit('showInfo', {
            msg: message
          });

          this.redirectWithDelay({ name: 'banners' });
        }
      } catch ({ response }) {

        const errors = _.get(response, 'data.errors', {});
        this.$refs.editBannerForm.setErrors(errors);

        EventBus.$emit('showErrors', {
          msg: response.data.message
        });
      }
    },
    async onSubmit() {
      await this.update();
    }
  },

  beforeMount() {
    this.getBannerFromRouteParam();
  },
};
</script>
