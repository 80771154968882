<template>
  <layout-dashboard>
    <user-form
        :userData.sync="userData"
        ref="createFormUser"
        @submit="onSubmit"
    ></user-form>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import UserForm from './UserForm';

export default {
  name: "CreateUserForm",
  components: { UserForm },
  mixins: [global],
  data: () => ({
    userData: {},
  }),
  methods: {
    async store() {
      const storeUserUrl = `${process.env.VUE_APP_API_URL}/api/v1/user/create`;

      try {
        const { data: { user: user, message } } = await axios.post(storeUserUrl, this.userData);

        this.$refs.createFormUser.stopLoading();
        this.$refs.createFormUser.clear()

        if (user.id && message) {
          EventBus.$emit('showInfo', {
            msg: message
          });

          this.redirectWithDelay({ name: 'users' });
        }
      } catch ({ response }) {

        const errors = _.get(response, 'data.errors', {});
        this.$refs.createFormUser.setErrors(errors);

        EventBus.$emit('showErrors', {
          msg: response.data.error
        });
      }
    },
    async onSubmit() {
      await this.store();
    }
  },
};
</script>
