<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand p-0">
          <div class="navbar-nav mr-auto">
            <h4 class="mb-0">Usuarios del sistema</h4>
          </div>
          <div class="navbar-nav flex-row-reverse ml-md-auto align-self-md-end pl-1">
            <router-link :to="{ name: 'users-create' }" class="btn btn-primary">
              <i class="fas fa-plus"></i>
              <span class="ml-1">Agregar</span>
            </router-link>
          </div>
        </nav>
      </div>
      <div class="col-md-12">
        <div class="users-tab">
          <div class="tab-content">
            <!-- Users Tab -->
            <div class="tab-pane show active">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <div class>
                    <table
                        id="table-users"
                        class="table table-hover table-center responsive w-100 mb-0"
                    >
                      <thead>
                      <tr>
                        <th>Nombre y apellidos</th>
                        <th>Usuario</th>
                        <th>Correo electrónico</th>
                        <th class="no-sort"></th>
                      </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  <!-- Popup modal confirm delete user -->
    <div class="text-center">
      <confirm-dialog
          ref="confirmDeleteUserDialog"
          header_title="Eliminar usuario"
          @accept="onAcceptConfirmDeleteUser"
      ></confirm-dialog>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";
import ConfirmDialog from '../Shared/ConfirmDialog';

export default {
  name: "UserList",
  components: { ConfirmDialog },
  mixins: [global, table],
  data() {
    return {
      tableFields: ['full_name', 'username', 'email'],
      datatableOptions: {
        order: [[1, "asc"]],
        columnDefs: [
          {
            targets: 0,
            responsivePriority: 1,
          },
          {
            targets: 2,
            responsivePriority: 1,
          },
          {
            targets: 3,
            responsivePriority: 2,
          },
          {
            targets: "no-sort",
            orderable: false,
          },
        ],
      },
    };
  },
  methods: {
    async getUsers() {

      const userListUrl = `${process.env.VUE_APP_API_URL}/api/v1/user/admins`;

      try {
        const { data: { users } } = await axios.get(userListUrl);

        this.data = users;
      } catch ({ response: { data: { error } } }) {

        EventBus.$emit('showErrors', {
          msg: error,
        });
      }
    },
    async buildUserDatatable() {

      if (this.dataTable) {
        this.dataTable.destroy();
      }

      this.dataTable = await this.initializeTable("#table-users", this.datatableOptions);

      await this.getUsers();

      this.generateTable();
    },
    tableActionsByObject(user) {
      let actions = { htmlText: '<div class="table-action">' };

      this.generateEditAction(user.id, 'users-edit', actions);

      this.generateDeleteAction(user.id, actions);

      return actions.htmlText;
    },
    async deleteUser() {

      const deleteUserUrl = `${process.env.VUE_APP_API_URL}/api/v1/user/delete/${this.dataIdSelected}`;

      try {
        const { data: { message: message } } = await axios.delete(deleteUserUrl);

        await this.buildUserDatatable();

        await EventBus.$emit('showInfo', { msg: message });
      } catch ({ response: { data } }) {

        EventBus.$emit('showErrors', {
          msg: _.get(data, 'message', data.error || '')
        });
      }
    },
    async onAcceptConfirmDeleteUser() {
      await this.deleteUser();
    },
  },
  beforeMount() {
  },
  mounted() {
    this.buildUserDatatable();

    const component = this;

    $('#table-users').on("click", ".action-delete", function () {
      $("#table-users tr.selected").removeClass("selected");
      $(this)
          .closest("tr")
          .addClass("selected");

      component.setDataIdSelected($(this).attr("attr_id"));
      component.$refs.confirmDeleteUserDialog.open();
    });
  },
};
</script>
