<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-10">
            <h4 class="mb-4">Detalles de la Persona</h4>
          </div>
        </div>

        <!-- About Me -->
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Datos de usuario</h4>
            <div class="row form-row">
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <p class="mb-1">Usuario</p>
                  <p class="text-muted mb-0">{{ userData.user.username }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <p class="mb-1">Email</p>
                  <p class="text-muted mb-0">{{ userData.user.email }}</p>
                </div>
              </div>
            </div>
            <div class="row form-row">
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <p class="mb-1">Nombre</p>
                  <p class="text-muted mb-0">{{ userData.user.names }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <p class="mb-1">Apellido</p>
                  <p class="text-muted mb-0">{{ userData.user.surnames }}</p>
                </div>
              </div>
            </div>
            <div class="row form-row">
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <p class="mb-1">Número de identificación</p>
                  <p class="text-muted mb-0">{{ userData.document }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <p class="mb-1">Teléfono</p>
                  <p class="text-muted mb-0">{{ userData.phone }}</p>
                </div>
              </div>
            </div>
            <div class="row form-row">
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <label>
                    Foto de Documento de Identidad
                  </label>
                  <div class="change-avatar">
                    <div class="profile-img">
                      <img
                        v-if="userData.document_image"
                        :src="userData.document_image"
                        alt="User Image"
                      />
                      <img
                        v-else
                        src="@/assets/img/profile.jpg"
                        alt="User Image"
                      />
                    </div>
                    <div class="upload-img">
                      <a download="foto-identidad" :href="userData.document_image" class="change-photo-btn text-decoration-none text-white">
                        <i class="fa fa-download"></i> Descargar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <label>
                    Foto de Perfil
                  </label>
                  <div class="change-avatar">
                    <div class="profile-img">
                      <img
                        v-if="userData.avatar"
                        :src="userData.avatar"
                        alt="User Image"
                      />
                      <img
                        v-else
                        src="@/assets/img/profile.jpg"
                        alt="User Image"
                      />
                    </div>
                    <div class="upload-img">
                      <a download="foto-perfil" :href="userData.avatar" class="change-photo-btn text-decoration-none text-white">
                        <i class="fa fa-download"></i> Descargar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row form-row">
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <p class="mb-1">Estado</p>
                  <p class="text-muted mb-0">
                    {{ userData.status.display_name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /About Me -->

        <!-- Contact Details -->
        <div class="card contact-card">
          <div class="card-body">
            <h4 class="card-title">Datos de contacto</h4>
            <div class="row form-row">
              <div class="col-md-12">
                <div class="form-group mb-0">
                  <p class="mb-1">Dirección</p>
                  <p class="text-muted mb-0">{{ userData.address.address }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <p class="mb-1">Ciudad</p>
                  <p class="text-muted mb-0">{{ userData.address.city }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <p class="mb-1">Estado / Provincia</p>
                  <p class="text-muted mb-0">{{ userData.address.state }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <p class="mb-1">País</p>
                  <p class="text-muted mb-0">
                    {{ userData.address.country.name }}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <p class="mb-1">Código Postal</p>
                  <p class="text-muted mb-0">{{ userData.address.zip }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Contact Details -->
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {
      userData: {
        document: "",
        phone: "",
        user: {
          names: "",
          surnames: "",
          username: "",
          email: "",
          password: "",
          password_confirmation: "",
        },
        address: {
          address: "",
          city: "",
          state: "",
          zip: "",
          country_id: "",
          country: "",
        },
        status: "",
        document_image: "",
        avatar: "",
        accept_terms: false,
      },
      countries: [],
      client_statuses: [],
      errors: [],
    };
  },
  mixins: [global],
  beforeMount() {
    //this.getCountries();

    axios
      .get(
        process.env.VUE_APP_API_URL +
          "/api/v1/profiles/person/get/" +
          this.$route.params.id
      )
      .then((response) => {
        //console.log(response.data.person);
        this.userData = response.data.person;
        //console.log(response.data.message);
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];

        EventBus.$emit("showErrors", {
          msg: error.response.data.error,
        });

        console.log(error.response.data.error);
      });
  },
  mounted() {},
  methods: {
    routeToClients: function() {
      this.$router.push("/clients");
    },
  },
};
</script>
