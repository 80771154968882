<template>
  <div class="LayoutDashboard">
    <div class="main-wrapper">
      <section-header></section-header>
      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
              <!-- Profile Sidebar -->
              <div class="profile-sidebar">
                <div class="dashboard-widget">
                  <nav class="dashboard-menu">
                    <section-menu></section-menu>
                  </nav>
                </div>
              </div>
              <!-- /Profile Sidebar -->
            </div>
            <div class="col-lg-9">
              <slot />
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <section-footer></section-footer>
    </div>
  </div>
</template>

<style src="@/assets/scss/dashboard.scss" lang="scss"></style>

<script>
export default {
  methods: {},
};
</script>
