<template>
  <div class="app-message">
    <div
      v-if="errors"
      class="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
      <div class="alert-heading">
        <i class="fa fa-exclamation-circle mr-1"></i>
        Error
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        v-on:click="clearMessages"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="alert-content">
        <span
          v-for="(value, key) in errors"
          v-bind:key="key"
          class="invalid-feedback"
          role="alert"
          >{{ value }}</span
        >
      </div>
    </div>
    <div
      v-if="msg"
      class="alert alert-info alert-dismissible fade show"
      role="alert"
    >
      <div class="alert-heading">
        <i class="fa fa-comment-o mr-1"></i>
        Información
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        v-on:click="clearMessages"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="alert-content">
        <span
          v-for="(value, key) in msg"
          v-bind:key="key"
          class="valid-feedback"
          role="alert"
          >{{ value }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";

export default {
  data() {
    return {
      hidden: true,
      errors: null,
      msg: null,
    };
  },
  created() {
    EventBus.$on("showErrors", (errors) => {
      const _self = this;
      const rand = (_self.hidden = Math.random());
      _self.errors = errors;
      setTimeout(function() {
        if (rand == _self.hidden) {
        _self.errors = null;
        _self.hidden = true;
        }
      }, 7000);
    });

    EventBus.$on("showInfo", (msg) => {
      const _self = this;
      const rand = (_self.hidden = Math.random());
      _self.msg = msg;
      setTimeout(function() {
        if (rand == _self.hidden) {
          _self.msg = null;
          _self.hidden = true;
        }
      }, 7000);
    });

    EventBus.$on("clearMessages", () => {
      this.clearMessages();
    });
  },
  methods: {
    clearMessages: function() {
      this.errors = null;
      this.msg = null;
      this.hidden = true;
    },
  },
};
</script>
