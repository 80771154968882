<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="saveExchangeRate" method="POST">
          <div class="row">
            <div class="col-md-10">
              <h4 class="mb-4">
                <span v-if="$route.params.id">Editar</span
                ><span v-else>Adicionar</span> tasa del día
              </h4>
            </div>
          </div>

          <!-- ExchangeRate -->
          <div class="card">
            <div class="card-body">
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>De</label>
                    <span class="text-danger">*</span>
                    <select
                      v-model="exchangeRateData.currency_from_id"
                      class="custom-select"
                    >
                      <option
                        v-for="(value, key) in currencies"
                        v-bind:key="key"
                        :value="value.id"
                        >{{ value.acronym }}</option
                      >
                    </select>
                    <small v-if="errors.currency_from_id" class="text-danger">{{
                      errors.currency_from_id[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>A</label>
                    <span class="text-danger">*</span>
                    <select
                      v-model="exchangeRateData.currency_to_id"
                      class="custom-select"
                    >
                      <option
                        v-for="(value, key) in currencies"
                        v-bind:key="key"
                        :value="value.id"
                        >{{ value.acronym }}</option
                      >
                    </select>
                    <small v-if="errors.currency_to_id" class="text-danger">{{
                      errors.currency_to_id[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Tasa de cambio</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="exchangeRateData.value"
                      type="number"
                      class="form-control"
                      min="0"
                      step="any"
                    />
                    <small v-if="errors.value" class="text-danger">{{
                      errors.value[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Fecha</label>
                    <span class="text-danger">*</span>
                    <v-menu class="datepicker">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          color="primary"
                          dark
                          elevation="0"
                          v-on="on"
                          class="datepicker-icon"
                        >
                          <v-icon>mdi-calendar</v-icon>
                        </v-btn>
                        <v-text-field
                          v-model="exchangeRateData.date"
                          v-on="on"
                          class="datepicker-text-field form-control"
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-if="!exchangeRateData.id"
                        v-model="exchangeRateData.date"
                        :min="new Date().toISOString().slice(0, 10)"
                        locale="es-US"
                      />
                    </v-menu>
                    <small v-if="errors.date" class="text-danger">{{
                      errors.date[0]
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /ExchangeRate -->

          <div class="submit-section submit-btn-bottom">
            <button type="submit" class="btn btn-primary">
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-secondary ml-1"
              @click="routeToExchangeRate()"
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {
      exchangeRateData: {
        id: "",
        currency_from_id: "",
        currency_to_id: "",
        value: "",
        date: new Date().toISOString().slice(0, 10),
      },
      currencies: [],
      errors: [],
    };
  },
  mixins: [global],
  beforeMount() {
    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/currency/list")
      .then((response) => {
        this.currencies = response.data.currencies;
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];
        console.log(error.response.data.message);
      });

    if (this.$route.params.id) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/exchange-rate/get/" +
            this.$route.params.id
        )
        .then((response) => {
          //console.log(response.data.exchangeRate);
          this.exchangeRateData = response.data.exchangeRate;
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    }
  },
  mounted() {},
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    routeToExchangeRate: function() {
      this.$router.push("/exchange-rate");
    },
    saveExchangeRate: function() {
      this.exchangeRateData.id ? this.update() : this.store();
    },
    store: function() {
      let exchangeRateData = JSON.parse(JSON.stringify(this.exchangeRateData));
      exchangeRateData.date = this.formatDate(this.exchangeRateData.date);
      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/v1/exchange-rate/create",
          exchangeRateData
        )
        .then((response) => {
          //console.log(response.data.exchangeRate);
          this.exchangeRateData = response.data.exchangeRate;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/exchange-rate");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    update: function() {
      let exchangeRateData = JSON.parse(JSON.stringify(this.exchangeRateData));
      exchangeRateData.date = this.formatDate(this.exchangeRateData.date);
      axios
        .put(
          process.env.VUE_APP_API_URL +
            "/api/v1/exchange-rate/update/" +
            this.exchangeRateData.id,
          exchangeRateData
        )
        .then((response) => {
          //console.log(response.data.exchangeRate);
          this.exchangeRateData = response.data.exchangeRate;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/exchange-rate");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
  },
};
</script>
