<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand p-0">
          <div class="navbar-nav mr-auto">
            <h4 class="mb-0">Métodos de recarga</h4>
          </div>
          <div
            class="navbar-nav flex-row-reverse ml-md-auto align-self-md-end pl-1"
          >
            <router-link to="/recharge-methods/edit" class="btn btn-primary">
              <i class="fas fa-plus"></i>
              <span class="ml-1">Agregar</span>
            </router-link>
          </div>
        </nav>
      </div>
      <div class="col-md-12">
        <div class="recharge-tab">
          <div class="tab-content">
            <!-- Recharge Tab -->
            <div class="tab-pane show active">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <div class>
                    <table
                      id="table-recharge"
                      class="table table-hover table-center responsive w-100 mb-0"
                    >
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Tipo de Comisión</th>
                          <th>Monto</th>
                          <th class="no-sort"></th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Recharge Tab -->
          </div>
        </div>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";

export default {
  data() {
    return {
      recharge_methods: [],
      dataTable: null,
      tableFields: ["name", "commission_type.display_name", "commission"],
      errors: [],
    };
  },
  mixins: [global, table],
  beforeMount() {},
  mounted() {
    this.dataTable = this.initializeTable("#table-recharge");

    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/recharge-way/list")
      .then((response) => {
        this.recharge_methods = response.data.recharge_ways;
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];
        console.log(error.response.data.message);
      })
      .finally(() => {
        this.generateTableWithData(this.recharge_methods, this.tableFields);

        //this.renderTable();

        var _self = this;

        $("#table-recharge").on("click", ".action-enable", function() {
          $("#table-recharge tr.selected").removeClass("selected");
          $(this)
            .closest("tr")
            .addClass("selected");

          _self.enable($(this).attr("attr_id"));
        });

        $("#table-recharge").on("click", ".action-disable", function() {
          $("#table-recharge tr.selected").removeClass("selected");
          $(this)
            .closest("tr")
            .addClass("selected");

          _self.disable($(this).attr("attr_id"));
        });

        console.log("Component mounted.");
      });
  },
  methods: {
    enable(id) {
      axios
        .get(
          process.env.VUE_APP_API_URL + "/api/v1/recharge-way/enable/" + id
        )
        .then((response) => {
          let index = this.recharge_methods.findIndex((x) => x.id == id);

          this.recharge_methods[index].active = 1;

          this.updateRowTable(this.recharge_methods[index]);

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    disable(id) {
      axios
        .get(
          process.env.VUE_APP_API_URL + "/api/v1/recharge-way/disable/" + id
        )
        .then((response) => {
          let index = this.recharge_methods.findIndex((x) => x.id == id);

          this.recharge_methods[index].active = 0;

          this.updateRowTable(this.recharge_methods[index]);

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    tableActionsByObject: function(rechargeMethod) {
      let actions = '<div class="table-action">';

      if (rechargeMethod.active == 0) {
        actions +=
          `
                      <a href="javascript:void(0);" attr_id="` +
            rechargeMethod.id +
          `" class="btn btn-sm bg-success-light action-enable">
                        <i class="fas fa-check"></i> Activar
                      </a>`;
      } else {
        actions +=
          `
                      <a href="javascript:void(0);" attr_id="` +
            rechargeMethod.id +
          `" class="btn btn-sm bg-danger-light action-disable">
                        <i class="fas fa-times"></i> Desactivar
                      </a>`;
      }

      actions += this.generateEditAction(rechargeMethod.id, 'recharge-methods-edit');

      actions += '</div>';

      return actions;
    },
  },
};
</script>
