<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand p-0">
          <div class="navbar-nav mr-auto">
            <h4 class="mb-0">Banner de anuncios</h4>
          </div>
          <div class="navbar-nav flex-row-reverse ml-md-auto align-self-md-end pl-1">
            <router-link :to="{name: 'banners-create'}" class="btn btn-primary">
              <i class="fas fa-plus"></i>
              <span class="ml-1">Agregar</span>
            </router-link>
          </div>
        </nav>
      </div>
      <div class="col-md-12">
        <div class="users-tab">
          <div class="tab-content">
            <!-- Users Tab -->
            <div class="tab-pane show active">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <div class>
                    <table
                        id="table-banners"
                        class="table table-hover table-center responsive w-100 mb-0"
                    >
                      <thead>
                      <tr>
                        <th>Título</th>
                        <th>Imagen</th>
                        <th class="no-sort"></th>
                      </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <banner-details
        ref="bannerDetails"
        :banner="bannerSelected"
        @activate="activateBanner"
    >
    </banner-details>
    <loading-progress :show="loading"></loading-progress>

    <div class="text-center">
      <confirm-dialog
          ref="confirmDeleteBannerDialog"
          header_title="Eliminar banner"
          @accept="onAcceptConfirmDeleteBanner"
      ></confirm-dialog>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";
import BannerDetails from './Details';
import LoadingProgress from '../Shared/LoadingProgress';
import ConfirmDialog from '../Shared/ConfirmDialog';

export default {
  components: { ConfirmDialog, BannerDetails, LoadingProgress },
  mixins: [global, table],
  data() {
    return {
      bannerSelected: {},
      tableFields: ['title', 'name'],
      datatableOptions: {
        order: [[1, "asc"]],
        columnDefs: [
          {
            targets: 0,
            responsivePriority: 1,
          },
          {
            targets: 1,
            responsivePriority: 1,
          },
          {
            targets: "no-sort",
            orderable: false,
          },
        ],
      },
    };
  },
  beforeMount() {
    this.bannerSelected = {
      active: false,
    };
  },
  mounted() {
    this.buildBannerDatatable();

    const component = this;

    /* Action details */
    $('#table-banners').on("click", ".action-details", async function () {
      $("#table-banners tr.selected").removeClass("selected");
      $(this)
          .closest("tr")
          .addClass("selected");

      component.setDataIdSelected($(this).attr("attr_id"));

      await component.getBanner();

      component.$refs.bannerDetails.open();
    });

    /* Action activate */
    $('#table-banners').on("click", ".action-activate", function () {
      $("#table-banners tr.selected").removeClass("selected");
      $(this)
          .closest("tr")
          .addClass("selected");

      component.setDataIdSelected($(this).attr("attr_id"));
      component.activateBanner();
    });

    /* Action delete */
    $('#table-banners').on("click", ".action-delete", function () {
      $("#table-users tr.selected").removeClass("selected");
      $(this)
          .closest("tr")
          .addClass("selected");

      component.setDataIdSelected($(this).attr("attr_id"));
      component.$refs.confirmDeleteBannerDialog.open();
    });
  },
  methods: {
    async getBanners() {

      const bannerListUrl = `${process.env.VUE_APP_API_URL}/api/v1/banners`;

      try {
        const { data: { banners } } = await axios.post(bannerListUrl);

        this.data = banners;
      } catch ({ response: { data: { error } } }) {

        EventBus.$emit('showErrors', {
          msg: error,
        });
      }
    },
    async getBanner() {
      const bannerUrl = `${process.env.VUE_APP_API_URL}/api/v1/banners/get/${this.dataIdSelected}`;

      try {
        const { data: { banner } } = await axios.get(bannerUrl);

        this.dataSelected = banner;
      } catch ({ response: { data: { error } } }) {

        EventBus.$emit('showErrors', {
          msg: error,
        });
      }
    },
    async activateBanner() {
      const bannerActivateUrl = `${process.env.VUE_APP_API_URL}/api/v1/banners/activate/${this.dataIdSelected}`;

      try {
        this.startLoading();

        const { data: { banner, message, success } } = await axios.put(bannerActivateUrl);

        if (success) {
          this.$refs.bannerDetails.close();

          this.dataSelected = banner;

          EventBus.$emit('showInfo', {
            msg: message,
          });

          this.stopLoading();

          await this.buildBannerDatatable();
        } else {
          EventBus.$emit('showErrors', {
            msg: message,
          });
        }
      } catch ({ response: { data: { message } } }) {
        EventBus.$emit('showErrors', {
          msg: message,
        });
      } finally {
        this.stopLoading();
      }
    },
    async buildBannerDatatable() {

      if (this.dataTable) {
        this.dataTable.destroy();
      }

      this.dataTable = await this.initializeTable("#table-banners", this.datatableOptions);

      await this.getBanners();

      this.generateTable();
    },
    async deleteBanner() {
      const deleteBannerUrl = `${process.env.VUE_APP_API_URL}/api/v1/banners/${this.dataIdSelected}`;

      try {
        const { data: { message: message } } = await axios.delete(deleteBannerUrl);

        await this.buildBannerDatatable();

        await EventBus.$emit('showInfo', { msg: message });
      } catch ({ response: { data } }) {

        EventBus.$emit('showErrors', {
          msg: _.get(data, 'message', data.error || '')
        });
      }
    },
    tableActionsByObject(banner) {
      let actions = { htmlText: '<div class="table-action">' };
      const slug = banner.slug;

      const actionActivateButtontext = banner.active ? 'Activo' : 'Activar';
      const actionActiveIconClass = banner.active ? 'fas fa-star' : 'fas fa-check';
      const actionActiveButtonClass = banner.active ? 'btn btn-sm bg-danger-light' : 'btn btn-sm bg-success-light action-activate';

      actions.htmlText += `<a href="javascript:void(0);"
                                         class="${actionActiveButtonClass}"
                                         attr_id="${slug}">
                                        <i class="${actionActiveIconClass}"></i>
                                        ${actionActivateButtontext}
                                     </a>`;

      actions.htmlText += `<a href="javascript:void(0);"
                                         class="btn btn-sm bg-info-light action-details"
                                         attr_id="${slug}">
                                        <i class="fas fa-search-plus"></i>
                                        Ampliar
                                     </a>`;

      this.generateEditAction({ slug }, 'banners-edit', actions);

      this.generateDeleteAction(slug, actions, { disabled: !banner.can_be_deleted });

      return actions.htmlText;
    },
    async onAcceptConfirmDeleteBanner() {
      await this.deleteBanner();
    },
  },
  watch: {
    dataSelected(value) {
      if (value && value.slug) {
        this.bannerSelected = value;
      }
    }
  }
};
</script>
