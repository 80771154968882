import LayoutDashboard from "../views/Dashboard.vue";
import auth from "@/helpers/auth";
import {
  EventBus
} from "@/main";

export default {
  components: {
    LayoutDashboard
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    logout: function () {
      auth.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    redirectWithDelay: function (route) {
      var _self = this;
      
      setTimeout(function() {
        _self.$router.push(route);
      }, 3000);
    },
    checkIfLoggedIn: function () {
      const access_token = localStorage.getItem("access_token") || "";
      const created_at = parseInt(localStorage.getItem("created_at")) || "";
      const expires_in = parseInt(localStorage.getItem("expires_in")) || "";
      const now = new Date();
      const createdAtDate = new Date(created_at);

      return !!(auth.getters.isLoggedIn &&
          access_token &&
          created_at &&
          expires_in &&
          createdAtDate.setSeconds(createdAtDate.getSeconds() + expires_in) > now);
    },
    getCountries() {
      if (this.countries == null || !this.countries.length) {
        axios
          .get(process.env.VUE_APP_API_URL + '/api/v1/nomenclator/country')
          .then(response => {
            return this.countries = response.data.countries;
          })
          .catch(error => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            if (typeof this.errors === "undefined" || this.errors.length == 0) {
              EventBus.$emit("showErrors", {
                msg: error.response.data.message
              });
            }

            console.log(error.response.data.message);

            return [];
          });
      } else {
        return this.countries;
      }
    },
    getClientStatuses() {
      if (this.client_statuses == null || !this.client_statuses.length) {
        axios
          .get(process.env.VUE_APP_API_URL + '/api/v1/nomenclator/client-statuses')
          .then(response => {
            return this.client_statuses = response.data.client_statuses;
          })
          .catch(error => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            if (typeof this.errors === "undefined" || this.errors.length == 0) {
              EventBus.$emit("showErrors", {
                msg: error.response.data.message
              });
            }

            return null;
          });
      } else {
        return this.client_statuses;
      }
    },
    getCommissionTypes() {
      if (this.commission_types == null || !this.commission_types.length) {
        axios
          .get(process.env.VUE_APP_API_URL + '/api/v1/nomenclator/commission-types')
          .then(response => {
            return this.commission_types = response.data.commission_types;
          })
          .catch(error => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            if (typeof this.errors === "undefined" || this.errors.length == 0) {
              EventBus.$emit("showErrors", {
                msg: error.response.data.message
              });
            }

            return [];
          });
      } else {
        return this.commission_types;
      }
    },
    dateFormat: function (dateUnformatted) {
      if (dateUnformatted == null || dateUnformatted == '') {
        return '';
      }

      const date = new Date(dateUnformatted);
      const dateTimeFormat = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "numeric",
        day: "2-digit"
      });
      const [{
          value: month
        }, ,
        {
          value: day
        }, ,
        {
          value: year
        }
      ] = dateTimeFormat.formatToParts(date);

      return `${month}/${day}/${year}`;
    },
    formatCurrency: function (mount) {
      if (mount != "") {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        if (formatter.format(mount) == '$NaN')
          return '$0.00';

        //return formatter.format(parseFloat(mount).toFixed(2));
        return formatter.format(mount.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      }

      return mount;
    },
    stopLoading() {
      this.loading = false;
    },
    startLoading() {
      this.loading = true;
    }
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    ClientStatuses() {
      return this.getClientStatuses();
    },
    curUser() {
      return JSON.parse(auth.getters.curUser) || [];
    },
  }
}
