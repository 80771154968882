<template>
  <!-- Header -->
  <header class="header">
    <nav class="navbar navbar-expand-lg header-nav">
      <div class="navbar-header">
        <a id="mobile_btn" href="javascript:void(0);">
          <span class="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>
        <router-link to="/" class="navbar-brand logo">
          <img src="@/assets/img/aless-pay-logo.png" class="img-fluid" alt="Logo" />
        </router-link>
      </div>
      <div class="main-menu-wrapper">
        <div class="menu-header">
          <router-link to="/" class="menu-logo">
            <img src="@/assets/img/aless-pay-logo.png" class="img-fluid" alt="Logo" />
          </router-link>
          <a id="menu_close" class="menu-close" href="javascript:void(0);">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div class="main-nav">
          <section-menu></section-menu>
        </div>
      </div>
      <ul class="nav header-navbar-rht">
        <li class="nav-item contact-item">
          <div class="header-contact-detail">
            <p class="contact-header mb-0">
              Hola,
              <strong class="text-capitalize">{{ curUser.username }}</strong>
            </p>
            <p class="contact-info-header text-right">{{ curUser.roles[0].display_name }}</p>
          </div>
        </li>
        <li class="nav-item dropdown has-arrow logged-item">
          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
            <span class="user-img">
              <img
                class="rounded-circle"
                src="@/assets/img/doctors/doctor-thumb-02.jpg"
                width="31"
                alt="Darren Elder"
              />
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <!--
            <router-link class="dropdown-item" to="/profile">Perfil de usuario</router-link>
            -->
            <router-link class="dropdown-item" to="/#">
              <span @click="logout">Salir</span>
            </router-link>
          </div>
        </li>
      </ul>
    </nav>
  </header>
  <!-- /Header -->
</template>

<style src="../../assets/scss/header.scss" lang="scss" scoped></style>

<script>
import $ from "jquery";
import global from "@/helpers/global";

export default {
  mixins: [global],
  mounted() {
    var $wrapper = $(".main-wrapper");

    $("body").append('<div class="sidebar-overlay"></div>');
    $(document).on("click", "#mobile_btn", function() {
      $wrapper.toggleClass("slide-nav");
      $(".sidebar-overlay").toggleClass("opened");
      $("html").addClass("menu-opened");
      $("#task_window").removeClass("opened");
      return false;
    });

    $(document).on("click", "#menu_close", function() {
      $("html").removeClass("menu-opened");
      $(".sidebar-overlay").removeClass("opened");
      $("main-wrapper").removeClass("slide-nav");
    });
    $(".main-nav ul li:not(.submenu) a").click(function() {
      $("html").removeClass("menu-opened");
      $(".sidebar-overlay").removeClass("opened");
    });
  },
  methods: {},
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
};
</script>
