<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12 pb-0">
        <h4 class="mb-4">Comisión por retirar: {{ withdrawalData.name }}</h4>
      </div>

      <!-- WithdrawalCommission -->
      <div class="col-md-12 pt-0">
        <div class="card">
          <div class="card-body">
            <form
              @submit.prevent="update"
              method="POST"
              class="p-5 col-sm-10 mx-auto"
            >
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Tipo de comisión</label>
                <div class="col-md-8">
                  <select
                    v-model="withdrawalComissionData.commission_type_id"
                    class="custom-select"
                  >
                    <option
                      v-for="(value, key) in commission_types"
                      v-bind:key="key"
                      :value="value.id"
                      >{{ value.display_name }}</option
                    >
                  </select>
                  <small v-if="errors.commission_type_id" class="text-danger">{{
                    errors.commission_type_id[0]
                  }}</small>
                  <small v-else
                    >Selecciona el tipo de comisión si porcentaje o fijo</small
                  >
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-4 col-form-label"
                  >Monto de la comisión</label
                >
                <div class="col-md-8">
                  <input
                    v-model="withdrawalComissionData.value"
                    type="number"
                    class="form-control"
                    min="0"
                    step="any"
                  />
                  <small v-if="errors.value" class="text-danger">{{
                    errors.value[0]
                  }}</small>
                  <small v-else>Introduce la cantidad total a cobrar</small>
                </div>
              </div>

              <div class="submit-section submit-btn-bottom">
                <button type="submit" class="btn btn-primary">
                  Guardar
                </button>
                <button
                  type="button"
                  class="btn btn-secondary ml-1"
                  @click="routeToWithdrawalCommissions()"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- /WithdrawalCommission -->
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {
      withdrawalData: {
        id: "",
        name: "",
      },
      withdrawalComissionData: {
        withdrawal_way_id: "",
        commission_type_id: "",
        value: "",
      },
      commission_types: [],
      errors: [],
    };
  },
  mixins: [global],
  beforeMount() {
    this.getCommissionTypes();

    if (this.$route.params.id) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/withdrawal-way/get/" +
            this.$route.params.id
        )
        .then((response) => {
          //console.log(response.data.withdrawal_way);
          this.withdrawalData = response.data.withdrawal_way;
          this.withdrawalComissionData = {
            withdrawal_way_id: this.withdrawalData.id,
            commission_type_id: this.withdrawalData.system_commission
              ? this.withdrawalData.system_commission.commission_type_id
              : "",
            value: this.withdrawalData.system_commission
              ? this.withdrawalData.system_commission.value
              : "",
          };
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    }
  },
  mounted() {},
  methods: {
    routeToWithdrawalCommissions: function() {
      localStorage.setItem("commissionActiveTab", "#withdrawal");

      const _self = this;
      setTimeout(function() {
        _self.$router.push("/commissions");
      }, 3000);
    },
    update: function() {
      axios
        .put(
          process.env.VUE_APP_API_URL + "/api/v1/commission/withdrawal/update",
          this.withdrawalComissionData
        )
        .then((response) => {
          //console.log(response.data.withdrawal_way);
          this.withdrawalData = response.data.withdrawal_way;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.routeToWithdrawalCommissions();

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
  },
};
</script>
