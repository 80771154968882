<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="saveRechargeMethod" method="POST">
          <div class="row">
            <div class="col-md-10">
              <h4 class="mb-4">
                <span v-if="$route.params.id">Editar</span
                ><span v-else>Adicionar</span> método de recarga
              </h4>
            </div>
          </div>

          <!-- Recharge Method -->
          <div class="card">
            <div class="card-body">
              <div class="row form-row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label>Nombre</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="rechargeData.name"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.name" class="text-danger">{{
                      errors.name[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Descripción</label>
                    <textarea
                      v-model="rechargeData.description"
                      class="form-control"
                    />
                    <small v-if="errors.description" class="text-danger">{{
                      errors.description[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Tipo de comisión</label>
                    <span class="text-danger">*</span>
                    <select
                      v-model="rechargeData.commission_type_id"
                      class="custom-select"
                    >
                      <option
                        v-for="(value, key) in commission_types"
                        v-bind:key="key"
                        :value="value.id"
                        >{{ value.display_name }}</option
                      >
                    </select>
                    <small
                      v-if="errors.commission_type_id"
                      class="text-danger"
                      >{{ errors.commission_type_id[0] }}</small
                    >
                    <small v-else
                      >Selecciona el tipo de comisión si porcentaje o
                      fijo</small
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Monto de la comisión</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="rechargeData.commission"
                      type="number"
                      class="form-control"
                      min="0"
                      step="any"
                    />
                    <small v-if="errors.commission" class="text-danger">{{
                      errors.commission[0]
                    }}</small>
                    <small v-else>Introduce la cantidad total a cobrar</small>
                  </div>
                </div>
              </div>
              <div class="clearfix form-separator my-6">
                <span class="text-uppercase">Campos Adicionales</span>
              </div>
              <!-- Metadata -->
              <div
                class="form-row form-group-box"
                v-for="(field, key) in rechargeData.metadata"
                v-bind:key="key"
              >
                <div class="close-box">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    @click="deleteMetadata(key)"
                  >
                    <span aria-hidden="true">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
                <div class="form-row col-12">
                  <div class="col-sm-3 text-center">
                    <label for="title">Nombre </label>
                  </div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="rechargeData.metadata[key].label"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <!-- /Metadata -->
              <!-- New Metadata -->
              <div
                class="form-row form-group-box"
                v-for="(field, key) in rechargeData.metadata_new"
                v-bind:key="key"
              >
                <div class="close-box">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    @click="deleteCustomField(key)"
                    v-if="key > 0 || ($route.params.id && rechargeData.metadata.length) || ($route.params.id && !rechargeData.metadata.length && key)"
                  >
                    <span aria-hidden="true">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
                <div class="form-row col-12">
                  <div class="col-sm-3 text-center">
                    <label for="title">Nombre </label>
                    <span v-if="!$route.params.id || ($route.params.id && !rechargeData.metadata.length && !key)" class="text-danger">*</span>
                  </div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="rechargeData.metadata_new[key]"
                    />
                    <small v-if="errors['metadata_new.' + key]" class="text-danger">{{
                      errors['metadata_new.' + key][0]
                    }}</small>
                  </div>
                </div>
              </div>
              <!-- /New Metadata -->
              <div
                class="position-relative d-flex justify-content-center justify-content-lg-end"
              >
                <button
                  type="button"
                  class="more-item"
                  @click="addCustomField()"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <!-- /Recharge Method -->

          <div class="submit-section submit-btn-bottom">
            <button type="submit" class="btn btn-primary">
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-secondary ml-1"
              @click="routeToRechageMethods()"
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {
      rechargeData: {
        id: "",
        name: "",
        description: "",
        commission_type_id: "",
        commission: "",
        metadata: [],
        metadata_new: [""],
      },
      commission_types: [],
      errors: [],
    };
  },
  mixins: [global],
  beforeMount() {
    this.getCommissionTypes();

    if (this.$route.params.id) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/recharge-way/get/" +
            this.$route.params.id
        )
        .then((response) => {
          //console.log(response.data.recharge_way);
          if (response.data.recharge_way.metadata.length) {
            response.data.recharge_way.metadata_new = [];
          } else {
            response.data.recharge_way.metadata_new = [""];
          }
          this.rechargeData = response.data.recharge_way;
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    }
  },
  mounted() {},
  methods: {
    routeToRechageMethods: function() {
      this.$router.push("/recharge-methods");
    },
    saveRechargeMethod: function() {
      this.rechargeData.id ? this.update() : this.store();
    },
    store: function() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/v1/recharge-way/create",
          this.rechargeData
        )
        .then((response) => {
          //console.log(response.data.recharge_way);
          response.data.recharge_way.metadata_new = [];
          this.rechargeData = response.data.recharge_way;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/recharge-methods");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    update: function() {
      axios
        .put(
          process.env.VUE_APP_API_URL +
            "/api/v1/recharge-way/update/" +
            this.rechargeData.id,
          this.rechargeData
        )
        .then((response) => {
          //console.log(response.data.recharge_way);
          response.data.recharge_way.metadata_new = [];
          this.rechargeData = response.data.recharge_way;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/recharge-methods");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    addCustomField() {
      var item = "";
      this.rechargeData.metadata_new.push(item);
    },
    deleteCustomField(counter) {
      this.rechargeData.metadata_new.splice(counter, 1);
    },
    deleteMetadata(counter) {
      this.rechargeData.metadata.splice(counter, 1);

      if (!this.rechargeData.metadata.length && !this.rechargeData.metadata_new.length) {
        this.addCustomField();
      }
    },
  },
};
</script>
