var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('div',{staticClass:"col-md-12"},[_c('v-row',[_c('div',{staticClass:"col-md-10"},[_c('h4',{staticClass:"mb-4"},[_c('span',[_vm._v(_vm._s(_vm.headerText))]),_vm._v(" usuario ")])])]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"keypress":_vm.onChange}},[_c('v-card',[_c('v-card-text',{staticClass:"card-body"},[_c('v-row',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nombre")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"vid":"names","name":"Names"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.userDataForm.names),callback:function ($$v) {_vm.$set(_vm.userDataForm, "names", $$v)},expression:"userDataForm.names"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Apellidos")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"vid":"surnames","name":"surnames"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.userDataForm.surnames),callback:function ($$v) {_vm.$set(_vm.userDataForm, "surnames", $$v)},expression:"userDataForm.surnames"}})]}}],null,true)})],1)])]),_c('v-row',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nombre de usuario")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"vid":"username","name":"User names"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"lower-case",rawName:"v-lower-case"}],attrs:{"solo":"","error-messages":errors},model:{value:(_vm.userDataForm.username),callback:function ($$v) {_vm.$set(_vm.userDataForm, "username", $$v)},expression:"userDataForm.username"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Correo electrónico")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"vid":"email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"lower-case",rawName:"v-lower-case"}],attrs:{"solo":"","error-messages":errors},model:{value:(_vm.userDataForm.email),callback:function ($$v) {_vm.$set(_vm.userDataForm, "email", $$v)},expression:"userDataForm.email"}})]}}],null,true)})],1)])])],1)],1),_c('div',{staticClass:"submit-section submit-btn-bottom mt-8"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")]),_c('button',{staticClass:"btn btn-secondary ml-1",on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" Cancelar ")])])],1)]}}])})],1),_c('loading-progress',{attrs:{"show":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }