<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <h4 class="mb-4">Clientes</h4>
        <div class="clients-tab general-tab">
          <!-- Links -->
          <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="currentPath == 'clients' ? 'active' : ''"
                href="#person"
                data-toggle="tab"
                >Personas</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#company" data-toggle="tab">Empresas</a>
            </li>
          </ul>
          <!-- /Links -->
          <div class="tab-content">
            <!-- Person Tab -->
            <div class="tab-pane show active" id="person">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <clients-person></clients-person>
                </div>
              </div>
            </div>
            <!-- /Person Tab -->
            <!-- Company Tab -->
            <div class="tab-pane" id="company">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <clients-company></clients-company>
                </div>
              </div>
            </div>
            <!-- /Company Tab -->
          </div>
        </div>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
//import { EventBus } from "@/main";
import global from "@/helpers/global";

import ClientsPerson from "./Person.vue";
import ClientsCompany from "./Company.vue";

export default {
  components: {
    ClientsPerson,
    ClientsCompany,
  },
  data() {
    return {};
  },
  mixins: [global],
  beforeMount() {},
  mounted() {
    const activeTab = localStorage.getItem('clientsActiveTab');
    if(activeTab){
        $('.clients-tab .nav-tabs a[href="' + activeTab + '"]').tab('show');
        localStorage.removeItem('clientsActiveTab');
    }
  },
  methods: {},
};
</script>
