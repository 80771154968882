<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <h4 class="mb-4">Comisiones del sistema</h4>
        <div class="commissions-tab general-tab">
          <!-- Links -->
          <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="currentPath == 'commissions' ? 'active' : ''"
                href="#transfer"
                data-toggle="tab"
                >Transferencia</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#recharge" data-toggle="tab">Recarga</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#withdrawal" data-toggle="tab">Retiro</a>
            </li>
          </ul>
          <!-- /Links -->
          <div class="tab-content">
            <!-- Transfer Tab -->
            <div class="tab-pane show active" id="transfer">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <commission-transfer></commission-transfer>
                </div>
              </div>
            </div>
            <!-- /Transfer Tab -->
            <!-- Recharge Tab -->
            <div class="tab-pane" id="recharge">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <commission-recharge></commission-recharge>
                </div>
              </div>
            </div>
            <!-- /Recharge Tab -->
            <!-- Withdrawal Tab -->
            <div class="tab-pane" id="withdrawal">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <commission-withdrawal></commission-withdrawal>
                </div>
              </div>
            </div>
            <!-- /Withdrawal Tab -->
          </div>
        </div>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import global from "@/helpers/global";

import CommissionTransfer from "./Transfer.vue";
import CommissionRecharge from "./Recharge.vue";
import CommissionWithdrawal from "./Withdrawal.vue";

export default {
  components: {
    CommissionTransfer,
    CommissionRecharge,
    CommissionWithdrawal
  },
  data() {
    return {};
  },
  mixins: [global],
  beforeMount() {},
  mounted() {
    const activeTab = localStorage.getItem('commissionActiveTab');
    if(activeTab){
        $('.commissions-tab .nav-tabs a[href="' + activeTab + '"]').tab('show');
        localStorage.removeItem('commissionActiveTab');
    }
  },
  methods: {},
};
</script>
