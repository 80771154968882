export default {
  data() {
    return {
      dataTable: null,
      data: [],
      tableFields: [],
      dataIdSelected: null,
      dataSelected:null,
    };
  },
  methods: {
    initializeTable: function (table, custom_options = false) {
      var options = {
        pageLength: 15,
        order: [],
        bLengthChange: false,
        info: false,
        processing: true,
        language: {
          info: "_TOTAL_ Elementos",
          infoEmpty: "",
          infoFiltered: "(filtered de _MAX_ elementos)",
          emptyTable: "No hay datos para mostrar",
          sZeroRecords: "No hay datos para mostrar",
          paginate: {
            previous: "Anterior",
            next: "Siguiente"
          },
          processing: "<span class='fa-stack fa-xs'><i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i></span>&emsp;&nbsp;&nbsp;&nbsp;Cargando ...",
        },
        columnDefs: [{
            responsivePriority: 1,
            targets: 0
          },
          {
            responsivePriority: 2,
            targets: -1
          },
          {
            targets: "no-sort",
            orderable: false
          }
        ],
        initComplete: function () {
          $(this).closest(".dataTables_wrapper").find(".dataTables_processing").css('display', 'block');
          $(this).addClass('table-loading');
        },
        drawCallback: function () {
          var pagination = $(this)
            .closest(".dataTables_wrapper")
            .find(".dataTables_paginate");

          pagination.toggle(this.api().page.info().pages > 1);
        }
      };

      if (custom_options && typeof custom_options === 'object') {
        options = {
          ...options,
          ...custom_options
        };
      }

      let dta = $(table).DataTable(options);

      var _self = this;

      $(table).on('page.dt', function () {
        _self.renderTable();
      });

      $(".dataTables_length, .dataTables_filter").remove();

      return dta;
    },
    renderTable: function () {
      setTimeout(function () {
        $($.fn.dataTable.tables(true))
          .DataTable()
          .draw(false)
          .columns.adjust();
      }, 300);
    },
    createRow: function (obj, actions = true) {
      let row = [];

      let columns = _.clone(this.tableFields);

      if (columns !== null && Array.isArray(columns)) {
        columns.forEach(column => {
          const col_value = this.getColValue(obj, column);
          //row.push(this.wrapText(col_value !== "" ? col_value : ''));
          row.push(col_value);
        });
      } else {
        Object.keys(obj).forEach(column => {
          const col_value = this.getColValue(obj, column);
          //row.push(this.wrapText(col_value !== "" ? col_value : ''));
          row.push(col_value);
        });
      }

      if (actions) {
        if (typeof this.tableActionsByObject !== "undefined") {
          row.push(this.tableActionsByObject(obj));

          return row;
        }

        if (typeof this.tableActions !== "undefined")
          row.push(this.tableActions(obj.id));
        else
          row.push("");
      }

      return row;
    },
    generateTableWithData: function (data, actions = true, dta = this.dataTable) {
      dta.clear();

      data.forEach(obj => {
        let row = this.createRow(obj, actions);
        dta.row.add(row);
      });

      dta.draw();

      const table_id = dta.table().node().id;
      $('#' + table_id).closest(".dataTables_wrapper").find(".dataTables_processing").remove();
      $('#' + table_id).removeClass('table-loading');

      this.renderTable();
    },
    generateTable: function (actions = true, dta = this.dataTable) {
      this.generateTableWithData(this.data, actions, dta);
    },
    updateRowTable: function (obj, actions = true, dta = this.dataTable) {
      const table_id = dta.table().node().id;
      const tr = $("#" + table_id + " .selected");
      if (tr.hasClass('child')) {
        const row = dta.row(tr.prev()[0]);

        if (row.child.isShown()) {
          row.data(this.createRow(obj, actions)).draw(false);
        } else {
          dta.row(tr).data(this.createRow(obj, actions)).draw(false);
        }
      } else {
        dta.row(tr).data(this.createRow(obj, actions)).draw(false);
      }
    },
    getObjChilds: function (obj, property) {
      var value = [];
      if (Array.isArray(obj)) {
        obj.forEach(objColumn => {
          var v = _.get(objColumn, property[0]);
          if (property.length > 1) {
            var p1 = this._clone(property);
            p1.shift();
            v = this.getObjChilds(v, p1);

          }
          value.push(v);
        });
      } else {
        value = _.get(obj, property[0]);
        if (property.length > 1) {
          property.shift();
          value = this.getObjChilds(value, property);
        }
      }

      return value;
    },
    getColValue: function (obj, column) {
      let value = "";
      let callback = null;

      if (column.indexOf(':') > 0) {
        callback = column.split(':')[1];
        column = column.split(':')[0];
      }

      let col_name = column.split('.');
      let col_value = obj[col_name[0]];

      if (col_name.length > 1 && Array.isArray(col_value)) {
        col_name.shift();
        var childs = [...new Set(this.getObjChilds(col_value, col_name))];

        childs.forEach(v => {
          if (typeof v !== 'undefined' && v !== null) {
            if (callback !== null && callback != "") {
              v = this[callback](v);
            }
          } else {
            v = "";
          }
          value = value + ", " + v;
        });

        value = value.replace(', ', '');

        if (callback == null || callback == "") {
          value = this.wrapText(value);
        }
      } else {
        value = _.get(obj, column);

        if (typeof value !== 'undefined' && value !== null) {
          if (callback !== null && callback != "") {
            value = this[callback](value);
          } else {
            value = this.wrapText(value);
          }
        } else {
          value = "";
        }
      }

      return value;
    },
    wrapText: function (v, length = 20) {
      if (typeof v === "string" && v.length > length) {
        return '<span class="wrap-text" title="' + v + '">' + v + "</span>";
      } else if (typeof v === "object") {
        for (const [key, value] of Object.entries(v)) {
          switch (key) {
            case 'html':
              return value;
            default:
              return (value.length > length) ? '<span class="wrap-text" title="' + value + '">' + value + "</span>" : value;
          }
        }
      }

      return v;
    },
    _clone: function (obj) {
      if (typeof obj === "undefined") {
        return "";
      }
      return JSON.parse(JSON.stringify(obj));
    },
    filterColumn: function (search, column = 0, exact_match = true) {
      if (typeof search !== "undefined") {
        search = exact_match ? "^" + search + "$" : search;
        this.dataTable
          .column(column)
          .search(search, 1, 0)
          .draw();
      } else {
        this.dataTable
          .column(column)
          .search("", 0, 1)
          .draw();
      }
    },
    getFieldArrayFromObject: function (obj, key) {
      let parent_key = key.split('.')[0];

      return [
        ...new Set(
          _.map(obj, x => (x[parent_key] ? _.get(x, key) : "")).filter(function (el) {
            return el != "";
          })
        )
      ].sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    },
    getPropertyFromObject: function (obj, key) {
      var returnObj = [];

      if (!Array.isArray(obj)) {
        obj = [obj]
      }

      obj.forEach(_obj => {
        let obj_name = key.split('.');
        let obj_value = _obj[obj_name[0]];

        if (obj_name.length > 1 && Array.isArray(obj_value)) {
          obj_name.shift();
          var childs = [...new Set(this.getObjChilds(obj_value, obj_name))];

          childs.forEach(v => {
            returnObj.push(v);
          });
        } else {
          if (obj_name.length > 1) {
            obj_name.shift();
            obj_value = this.getObjChilds(obj_value, obj_name);
          }
          if (typeof obj_value === 'undefined') {
            obj_value = "";
          }
          returnObj.push(obj_value);
        }
      });

      return [...new Set(returnObj)];
    },
    generateEditAction(id, routeName, actions = null) {

      let params = { id };
      if (_.isObject(id)) {
        params = id;
      }
      const editLink = this.$router.resolve({ name: routeName, params, }).href;

      const htmlEditActionButton = `<a href="${editLink}" 
                                       class="btn btn-sm bg-warning-light action-edit">
                                       <i class="far fa-edit"></i>
                                       Editar
                                    </a>`;

      if (actions && _.isString(actions.htmlText)) {
        actions.htmlText += htmlEditActionButton;
      }

      return htmlEditActionButton;
    },
    generateDeleteAction(id, actions = null, options = { disabled: false }) {

      const htmlDeleteActionButton = `<a ${options.disabled ? 'disabled="disabled"' : ''}
                                         class="btn btn-sm ${options.disabled ? 'bg-default-light v-btn--disabled' : 'bg-danger-light action-delete'}"
                                         attr_id="${id}">
                                        <i class="fas fa-times"></i>
                                        Eliminar
                                     </a>`;

      if (actions && _.isString(actions.htmlText)) {
        actions.htmlText += htmlDeleteActionButton;
      }

      return htmlDeleteActionButton;
    },
    setDataIdSelected(id) {
      this.dataIdSelected = id;
    }
  },
  computed: {
    getDataSelected() {
      return this.data.find((dataObj) => dataObj.id === this.dataIdSelected);
    }
  },
  watch: {
    dataIdSelected() {
      this.dataSelected = this.getDataSelected;
    }
  }
}
