<template>
  <div>
    <table
      id="table-persons"
      class="table table-hover table-center responsive w-100 mb-0"
    >
      <thead>
        <tr>
          <th>Correo electrónico</th>
          <th>Usuario</th>
          <th>Nombre y Apellidos</th>
          <th>Saldo</th>
          <th>Estado</th>
          <th class="no-sort"></th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";

export default {
  data() {
    return {
      persons: [],
      dataTable: null,
      tableFields: [
        "user.email",
        "user.username",
        "user.full_name",
        "wallet.amount:formatCurrency",
        "id:badgeStatus",
      ],
      errors: [],
    };
  },
  mixins: [global, table],
  mounted() {
    var options = {
      order: [[4, "desc"]],
      columnDefs: [
        {
          responsivePriority: 1,
          targets: 0,
        },
        { targets: 3, responsivePriority: 4, className: "dt-body-right" },
        { targets: 4, responsivePriority: 3 },
        {
          responsivePriority: 2,
          orderable: false,
          targets: -1,
        },
      ],
    };

    this.dataTable = this.initializeTable("#table-persons", options);

    axios
      .post(process.env.VUE_APP_API_URL + "/api/v1/profiles/person/list")
      .then((response) => {
        this.persons = response.data.persons;
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];

        EventBus.$emit("showErrors", {
          msg: error.response.data.error,
        });

        console.log(error.response.data.error);
      })
      .finally(() => {
        this.generateTableWithData(this.persons, this.tableFields);

        console.log("Component mounted.");
      });
  },
  methods: {
    badgeStatus: function(id) {
      let person = this._clone(
        this.persons.filter(function(p) {
          return p.id == id;
        })[0]
      );

      if (person != undefined && person != "") {
        let html = '<span class="badge light badge-';

        if (person.user.active && person.status.active) {
          const statuses = {
            approved: "success",
            pending: "warning",
            denied: "danger",
            on_hold: "secondary",
          };

          html += statuses[person.status.name] ?? '';

          html += '">' + person.status.display_name + "</span>";
        } else {
          html += 'dark">Desactivado</span>';
        }

        return html;
      }

      return status;
    },
    tableActions: function(id) {
      let person = this._clone(
        this.persons.filter(function(p) {
          return p.id == id;
        })[0]
      );

      let actions = '<div class="table-action">';

      if (person.user.can_be_edited) {

        actions +=
          `<a href="` +
          this.$router.resolve({
            name: "client-person-edit",
            params: { id: id },
          }).href +
          `" class="btn btn-sm bg-warning-light">
                        <i class="far fa-edit"></i> Editar
                      </a>`;
      }

      actions +=
          `<a href="` +
          this.$router.resolve({
            name: "client-transactions-view",
            params: {id: person.user.id},
          }).href +
          `" class="btn btn-sm bg-info-light">
                <i class="far fa-eye"></i> Transacciones
                </a>`;

      actions += "</div>";

      return actions;
    },
  },
};
</script>
