<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand p-0">
          <div class="navbar-nav mr-auto">
            <h4 class="mb-0">Tasa del Día</h4>
          </div>
          <div
            class="navbar-nav flex-row-reverse ml-md-auto align-self-md-end pl-1"
          >
            <router-link to="/exchange-rate/edit" class="btn btn-primary">
              <i class="fas fa-plus"></i>
              <span class="ml-1">Establecer tasa del día</span>
            </router-link>
          </div>
        </nav>
      </div>
      <div class="col-md-12">
        <div class="exchange-rate-tab">
          <div class="tab-content">
            <!-- Exchange Rate Tab -->
            <div class="tab-pane show active">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <div class>
                    <table
                      id="table-exchange-rate"
                      class="table table-hover table-center responsive w-100 mb-0"
                    >
                      <thead>
                        <tr>
                          <th>De</th>
                          <th>A</th>
                          <th>Tasa de cambio</th>
                          <th>Fecha</th>
                          <th class="no-sort"></th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Exchange Rate Tab -->
          </div>
        </div>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import global from "@/helpers/global";
import table from "@/helpers/table";

export default {
  data() {
    return {
      exchange_rates: [],
      dataTable: null,
      tableFields: [
        "currency_from.acronym",
        "currency_to.acronym",
        "value",
        "date",
      ],
      errors: [],
    };
  },
  mixins: [global, table],
  beforeMount() {},
  mounted() {
    var options = {
      order: [[ 3, "desc" ]],
      columnDefs: [
        {
          targets: "no-sort",
          orderable: false,
        },
      ],
    };

    this.dataTable = this.initializeTable("#table-exchange-rate", options);

    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/exchange-rate/list")
      .then((response) => {
        this.exchange_rates = response.data.exchangeRates;
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];
        console.log(error.response.data.message);
      })
      .finally(() => {
        this.generateTableWithData(this.exchange_rates, this.tableFields);

        //this.renderTable();

        console.log("Component mounted.");
      });
  },
  methods: {
    tableActions: function(id) {
      let rate = this._clone(
        this.exchange_rates.filter(function(er) {
          return er.id == id;
        })[0]
      );

      let actions = "";

      if (rate.can_be_updated) {
        actions = '<div class="table-action">';

        actions +=
          `<a href="` +
          this.$router.resolve({
            name: "exchange-rate-edit",
            params: { id: id },
          }).href +
          `" class="btn btn-sm bg-warning-light">
                        <i class="far fa-edit"></i> Editar
                      </a>`;

        actions += "</div>";
      }

      return actions;
    },
  },
};
</script>
