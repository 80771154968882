import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        status: '',
        access_token: localStorage.getItem('access_token') || '',
        refresh_token: localStorage.getItem('refresh_token') || '',
        expires_in: localStorage.getItem('expires_in') || '',
        created_at: localStorage.getItem('created_at') || '',
        user: localStorage.getItem('user') || '',
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, params) {
            state.status = 'success'
            state.access_token = params.access_token
            state.refresh_token = params.refresh_token
            state.expires_in = params.expires_in
            state.created_at = params.created_at
            state.user = params.user
            //console.log(state)
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.access_token = ''
            state.refresh_token = ''
            state.expires_in = ''
            state.created_at = ''
            state.user = ''
        },
    },
    actions: {
        login({
            commit
        }, data_login) {
            data_login.grant_type = 'password'
            data_login.client_id = 2
            data_login.client_secret = process.env.VUE_APP_API_KEY
            //let reqData = Object.keys(data_login).map(k => encodeURIComponent(k) + "=" + encodeURIComponent(data_login[k])).join("&")
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                        url: process.env.VUE_APP_API_URL + '/api/v1/security/login',
                        data: data_login,
                        method: 'POST'
                    })
                    .then(resp => {
                        const params = {}
                        params.access_token = resp.data.access_token
                        params.refresh_token = resp.data.refresh_token
                        params.expires_in = resp.data.expires_in
                        params.created_at = new Date().getTime()
                        params.user = JSON.stringify(resp.data.user)

                        localStorage.setItem('access_token', params.access_token)
                        localStorage.setItem('refresh_token', params.refresh_token)
                        localStorage.setItem('expires_in', params.expires_in)
                        localStorage.setItem('created_at', params.created_at)
                        localStorage.setItem('user', params.user)
                        
                        // Add the following line:
                        axios.defaults.headers.common['Authorization'] = `Bearer ${params.access_token}`
                        commit('auth_success', params)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('access_token')
                        localStorage.removeItem('refresh_token')
                        localStorage.removeItem('expires_in')
                        localStorage.removeItem('created_at')
                        localStorage.removeItem('user')
                        reject(err)
                    })
            })
        },
        refresh({
            commit
        }, refresh_token) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                        url: process.env.VUE_APP_API_URL + '/oauth/token',
                        data: {
                            "grant_type": "refresh_token",
                            "client_id": "2",
                            "client_secret": "t3jAvTcQAvUNptrJw2ZqVaM0zZS1GglORzFSi7EE",
                            "refresh_token": refresh_token
                        },
                        method: 'POST'
                    })
                    .then(resp => {
                        const params = {}
                        params.access_token = resp.data.access_token
                        params.refresh_token = resp.data.refresh_token
                        params.expires_in = resp.data.expires_in
                        params.created_at = new Date().getTime()
                        
                        localStorage.setItem('access_token', params.access_token)
                        localStorage.setItem('refresh_token', params.refresh_token)
                        localStorage.setItem('expires_in', params.expires_in)
                        localStorage.setItem('created_at', params.created_at)

                        // Add the following line:
                        axios.defaults.headers.common['Authorization'] = `Bearer ${params.access_token}`
                        commit('auth_success', params)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        localStorage.removeItem('access_token')
                        localStorage.removeItem('refresh_token')
                        localStorage.removeItem('expires_in')
                        localStorage.removeItem('created_at')
                        reject(err)
                    })
            })
        },
        logout({
            commit
        }) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem('expires_in')
                localStorage.removeItem('created_at')
                localStorage.removeItem('user')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        }
    },
    getters: {
        isLoggedIn: state => !!state.access_token,
        authStatus: state => state.status,
        curUser: state => state.user,
    }
})