<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <h4 class="mb-4">Cambiar contraseña</h4>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
//import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {};
  },
  mixins: [global],
  beforeMount() {},
  mounted() {},
  methods: {},
};
</script>
