<template>
  <v-row justify="center">
    <v-dialog
        v-model="show"
        width="900"
        persistent
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="onActivate"
              :disabled="bannerActive"
          >
            {{ buttonActiveText }}
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="onClose"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" lg="6" xl="6" md="6">
                <div><span class="font-weight-bold">Título: </span>{{ bannerTitle }}</div>
              </v-col>
              <v-col cols="12" lg="6" xl="6" md="6">
                <div><span class="font-weight-bold">Nombre: </span>{{ bannerName }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-img
                    eager
                    :src="bannerImage"
                >
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "BannerDetails",
  props: {
    banner: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    onClose() {
      this.close();

      this.$emit('close');
    },
    onActivate() {
      this.$emit('activate');
    },
  },
  computed: {
    bannerActive() {
      return _.get(this.banner, 'active', false);
    },
    buttonActiveText() {
      return this.bannerActive ? 'Activado' : 'Activar';
    },
    bannerTitle() {
      return _.get(this.banner, 'title', '');
    },
    bannerName() {
      return _.get(this.banner, 'name', '');
    },
    bannerImage() {
      return _.get(this.banner, 'image');
    }
  }
};
</script>
