<template>
  <div class="row">
    <div class="col-md-12">
      <table
        id="table-recharge"
        class="table table-hover table-center responsive w-100 mb-0"
      >
        <thead>
          <tr>
            <th>Método de recarga</th>
            <th>Tipo de Comisión</th>
            <th>Monto</th>
            <th class="no-sort"></th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
</template>

<script>
import global from "@/helpers/global";
import table from "@/helpers/table";

export default {
  data() {
    return {
      recharge_methods: [],
      dataTable: null,
      tableFields: [
        "name",
        "system_commission.commission_type.display_name",
        "system_commission.value",
      ],
      errors: [],
    };
  },
  mixins: [global, table],
  beforeMount() {},
  mounted() {
    this.dataTable = this.initializeTable("#table-recharge");

    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/recharge-way/all-active")
      .then((response) => {
        this.recharge_methods = response.data.recharge_ways;
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];
        console.log(error.response.data.message);
      })
      .finally(() => {
        this.generateTableWithData(this.recharge_methods, this.tableFields);

        //this.renderTable();

        console.log("Component mounted.");
      });

    var _self = this;

    $('a[data-toggle="tab"][href="#recharge"]').on("shown.bs.tab", function() {
      _self.renderTable();
    });
  },
  methods: {
    tableActions: function(id) {
      let actions = '<div class="table-action">';

      actions +=
        `<a href="` +
        this.$router.resolve({
          name: "commissions-recharge",
          params: { id: id },
        }).href +
        `" class="btn btn-sm bg-warning-light">
                        <i class="far fa-edit"></i> Editar
                      </a>`;

      actions += "</div>";

      return actions;
    },
  },
};
</script>
