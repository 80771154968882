import Vue from 'vue'
import App from './App.vue'
import router from './router'
import auth from './helpers/auth'
import { localize } from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'

localize('es', es);

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.min.css';

import Vuetify from 'vuetify'
Vue.use(Vuetify)
Vue.use(vueFilterPrettyBytes)

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import 'datatables.net-responsive-dt'

Vue.filter('lowerCase', (value) => {
    return _.isString(value) ? _.toLower(value) : value;
});

Vue.directive('lower-case', (el) => {
    el.value = Vue.options.filters.lowerCase(el.value);
});

Vue.directive('lower-case', {
    bind(el, __, vNode) {
        el.emitInput = () => {
            if (vNode.componentInstance) {
                const valueLowerCase = Vue.options.filters.lowerCase(vNode.componentInstance.$el.getElementsByTagName('input')[0].value);
                vNode.componentInstance.$emit('input', valueLowerCase)
                return
            }
            const valueLowerCase = Vue.options.filters.lowerCase(el.value);
            if (el.value !== valueLowerCase) {
                el.value = valueLowerCase;
                el.dispatchEvent(new Event('input'));
            }
        };

        el.addEventListener('input', () => {
            el.emitInput();
        });
    },
    unbind(el) {
        el.removeEventListener('input', el.emitInput());
    }
});

import './assets/scss/style.scss'

Vue.config.productionTip = false

export const EventBus = new Vue()

new Vue({
  router,
  auth,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount('#app')
