<template>
  <v-row>
    <div class="col-md-12">
      <v-row>
        <div class="col-md-10">
          <h4 class="mb-4">
            <span>{{ headerText }}</span> usuario
          </h4>
        </div>
      </v-row>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" @keypress="onChange">
          <v-card>
            <v-card-text class="card-body">
              <v-row class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Nombre</label>
                    <span class="text-danger">*</span>
                    <ValidationProvider vid="names" name="Names" v-slot="{ errors }">
                      <v-text-field
                          v-model="userDataForm.names"
                          :error-messages="errors"
                          solo
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Apellidos</label>
                    <span class="text-danger">*</span>
                    <ValidationProvider vid="surnames" name="surnames" v-slot="{ errors }">
                      <v-text-field
                          v-model="userDataForm.surnames"
                          :error-messages="errors"
                          solo
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </div>
                </div>
              </v-row>

              <v-row class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Nombre de usuario</label>
                    <span class="text-danger">*</span>
                    <ValidationProvider vid="username" name="User names" v-slot="{ errors }">
                      <v-text-field
                          solo
                          v-lower-case
                          v-model="userDataForm.username"
                          :error-messages="errors"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Correo electrónico</label>
                    <span class="text-danger">*</span>
                    <ValidationProvider vid="email" name="Email" v-slot="{ errors }">
                      <v-text-field
                          solo
                          v-lower-case
                          v-model="userDataForm.email"
                          :error-messages="errors"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>

          <div class="submit-section submit-btn-bottom mt-8">
            <button
                type="submit"
                class="btn btn-primary"
            >
              Guardar
            </button>
            <button
                class="btn btn-secondary ml-1"
                @click="onCancel()"
            >
              Cancelar
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <loading-progress :show="loading"></loading-progress>
  </v-row>
</template>

<script>
import global from "@/helpers/global";
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, min } from 'vee-validate/dist/rules';
import LoadingProgress from '../Shared/LoadingProgress';

extend('required', {
  ...required,
});

extend('min', {
  ...min,
});

export default {
  name: "UserForm",
  components: { LoadingProgress, ValidationObserver, ValidationProvider },
  mixins: [global],
  props: {
    userData: {
      type: Object,
      default: () => ({
        names: '',
        surnames: '',
        email: '',
        username: '',
      })
    }
  },
  data: () => ({
    userDataForm: {}
  }),
  methods: {
    onSubmit() {
      this.startLoading();

      this.$emit('submit');
    },
    onCancel() {
      this.$refs.form.reset();
      this.clear();

      this.$router.push({ name: 'users' });
    },
    onChange() {
      this.$emit('update:userData', this.userDataForm);
    },
    clear() {
      this.userDataForm = {
        names: '',
        surnames: '',
        email: '',
        username: '',
      };

      this.$emit('update:userData', this.userDataForm);
    },
    setErrors(errors) {
      this.$refs.form.setErrors(errors);

      setTimeout(() => {
        this.stopLoading()
      }, 100);
    },
  },
  computed: {
    isEditForm() {
      return !!_.get(this.userData, 'id', false);
    },
    headerText() {
      return this.isEditForm ? 'Editar' : 'Adicionar';
    },
  },
  watch: {
    userData(newValue) {
      if (newValue.id) {
        this.userDataForm = this.userData;
      }
    }
  }
};
</script>
