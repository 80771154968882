import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/helpers/auth";

import SectionHeader from "@/components/sections/Header";
import SectionMenu from "@/components/sections/Menu";
import SectionFooter from "@/components/sections/Footer";
import Messages from "@/components/Messages";

import { EventBus } from "@/main";

/*Global Components */
Vue.component("section-header", SectionHeader);
Vue.component("section-menu", SectionMenu);
Vue.component("section-footer", SectionFooter);
Vue.component("messages", Messages);

Vue.use(VueRouter);

const routes = [
  {
    path: "/confirmar-email/:email?/:code(\\d+)?",
    name: "confirmar-email",
    component: require("@/components/Login/ConfirmEmail.vue").default,
  },
  {
    path: "/email-confirmado",
    name: "email-confirmado",
    component: require("@/components/Login/ConfirmSuccess.vue").default,
  },
  {
    path: "/login",
    name: "login",
    component: require("@/components/Login.vue").default,
  },
  {
    path: "/",
    name: "home",
    component: require("@/components/Recharge/Index.vue").default,
  },
  {
    path: "/clients",
    name: "clients",
    component: require("@/components/Clients/Index.vue").default,
  },
  {
    path: "/clients/person/view/:id",
    name: "client-person-view",
    component: require("@/components/Clients/PersonDetails.vue").default,
  },
  {
    path: "/clients/person/edit/:id",
    name: "client-person-edit",
    component: require("@/components/Clients/PersonEdit.vue").default,
  },
  {
    path: "/clients/company/view/:id",
    name: "client-company-view",
    component: require("@/components/Clients/CompanyDetails.vue").default,
  },
  {
    path: "/clients/company/edit/:id",
    name: "client-company-edit",
    component: require("@/components/Clients/CompanyEdit.vue").default,
  },
  {
    path: "/clients/transactions/view/:id",
    name: "client-transactions-view",
    component: require("@/components/Transactions/Transactions.vue").default,
  },
  {
    path: "/clients/transaction/add/:id",
    name: "client-transaction-add",
    component: require("@/components/Transactions/NewTransaction.vue").default,
  },
  {
    path: "/currency",
    name: "currency",
    component: require("@/components/Currency/Index.vue").default,
  },
  {
    path: "/currency/edit/:id?",
    name: "currency-edit",
    component: require("@/components/Currency/Edit.vue").default,
  },
  {
    path: "/commissions",
    name: "commissions",
    component: require("@/components/Commissions/Config.vue").default,
  },
  {
    path: "/commissions/recharge/:id?",
    name: "commissions-recharge",
    component: require("@/components/Commissions/RechargeEdit.vue").default,
  },
  {
    path: "/commissions/withdrawal/:id?",
    name: "commissions-withdrawal",
    component: require("@/components/Commissions/WithdrawalEdit.vue").default,
  },
  {
    path: "/orders",
    name: "orders",
    component: require("@/components/Recharge/Index.vue").default,
  },
  {
    path: "/order/view/:id",
    name: "order-view",
    component: require("@/components/Recharge/Details.vue").default,
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: require("@/components/Withdrawal/Index.vue").default,
  },
  {
    path: "/withdrawal/view/:id",
    name: "withdrawal-view",
    component: require("@/components/Withdrawal/Details.vue").default,
  },
  {
    path: "/withdrawal-methods/",
    name: "withdrawal-methods",
    component: require("@/components/Withdrawal/Methods.vue").default,
  },
  {
    path: "/withdrawal-methods/edit/:id?",
    name: "withdrawal-methods-edit",
    component: require("@/components/Withdrawal/Edit.vue").default,
  },
  {
    path: "/recharge-methods",
    name: "recharge-methods",
    component: require("@/components/Recharge/Methods.vue").default,
  },
  {
    path: "/recharge-methods/edit/:id?",
    name: "recharge-methods-edit",
    component: require("@/components/Recharge/Edit.vue").default,
  },
  {
    path: "/exchange-rate",
    name: "exchange-rate",
    component: require("@/components/ExchangeRate/Index.vue").default,
  },
  {
    path: "/exchange-rate/edit/:id?",
    name: "exchange-rate-edit",
    component: require("@/components/ExchangeRate/Edit.vue").default,
  },
  {
    path: "/banners",
    name: "banners",
    component: require("@/components/Banner/Index.vue").default,
  },
  {
    path: "/banners/create",
    name: "banners-create",
    component: require("@/components/Banner/CreateBannerForm.vue").default,
  },
  {
    path: "/banners/edit/:slug",
    name: "banners-edit",
    component: require("@/components/Banner/EditBannerForm.vue").default,
  },
  {
    path: "/users",
    name: "users",
    component: require("@/components/Users/Index.vue").default,
    meta: {
      roleSuperAdmin: true,
    },
  },
  {
    path: "/users/create",
    name: "users-create",
    component: require("@/components/Users/CreateUserForm.vue").default,
    meta: {
      roleSuperAdmin: true,
    },
  },
  {
    path: "/users/edit/:id",
    name: "users-edit",
    component: require("@/components/Users/EditUserForm.vue").default,
    meta: {
      roleSuperAdmin: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: require("@/components/Profile.vue").default,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: require("@/components/ChangePassword.vue").default,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    /^login\/?$/,
    /^confirmar-email(\/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\/\d+)?\/?$/,
    /^email-confirmado\/?$/,
  ];
  const access_token = localStorage.getItem("access_token") || "";
  const created_at = parseInt(localStorage.getItem("created_at")) || "";
  const expires_in = parseInt(localStorage.getItem("expires_in")) || "";
  const now = new Date();

  const authRequired = !publicPages.some(function(e) {
    return e.test(to.path.substring(1));
  });

  if (
    authRequired &&
    (!auth.getters.isLoggedIn ||
      !access_token ||
      !created_at ||
      !expires_in ||
      new Date(new Date(created_at).setSeconds(new Date(created_at).getSeconds() + expires_in)) < now)
  ) {
    auth.dispatch("logout");
    return next("/login");
  }

  if (authRequired) {
    const curUser = JSON.parse(auth.getters.curUser);
    if (!curUser.roles.find((o) => o.name === "administrator")) {
      auth.dispatch("logout");
      return next("/");
    }
  }

  EventBus.$emit("clearMessages", true);

  if (
    authRequired &&
    new Date(new Date(created_at).setSeconds(new Date(created_at).getSeconds() + expires_in - 600)) < now
  ) {
    const refresh_token = localStorage.getItem("refresh_token") || "";
    auth.dispatch("refresh", refresh_token);
  }

  axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;

  document.title = "Aless Pay - Dashboard";

  if (to.matched.some((record) => record.meta.roleSuperAdmin)) {
    const curUser = JSON.parse(auth.getters.curUser);

    if (!curUser.is_super_administrator) {
      next({ name: "home" });
    }
  }

  next();
});

export default router;
