<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="saveCurrency" method="POST">
          <div class="row">
            <div class="col-md-10">
              <h4 class="mb-4"><span v-if="$route.params.id">Editar</span><span v-else>Adicionar</span> moneda</h4>
            </div>
          </div>

          <!-- Currency -->
          <div class="card">
            <div class="card-body">
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Nombre</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="currencyData.name"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.name" class="text-danger">{{
                      errors.name[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Abreviatura</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="currencyData.acronym"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.acronym" class="text-danger">{{
                      errors.acronym[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Recarga mínima</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="currencyData.min"
                      type="number"
                      class="form-control"
                      min="0"
                      step="any"
                    />
                    <small v-if="errors.min" class="text-danger">{{
                      errors.min[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Recarga máxima</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="currencyData.max"
                      type="number"
                      class="form-control"
                      min="0"
                      step="any"
                    />
                    <small v-if="errors.max" class="text-danger">{{
                      errors.max[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Descripción</label>
                    <textarea
                      v-model="currencyData.description"
                      class="form-control"
                    />
                    <small v-if="errors.description" class="text-danger">{{
                      errors.description[0]
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Currency -->

          <div class="submit-section submit-btn-bottom">
            <button type="submit" class="btn btn-primary">
              Guardar
            </button>
            <button type="button" class="btn btn-secondary ml-1" @click="routeToCurrency()">
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {
      currencyData: {
        id: "",
        name: "",
        acronym: "",
        min: "",
        max: "",
        description: "",
      },
      errors: [],
    };
  },
  mixins: [global],
  beforeMount() {
    if (this.$route.params.id) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/currency/get/" +
            this.$route.params.id
        )
        .then((response) => {
          //console.log(response.data.currency);
          this.currencyData = response.data.currency;
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    }
  },
  mounted() {},
  methods: {
    routeToCurrency: function () {
      this.$router.push("/currency");
    },
    saveCurrency: function() {
      this.currencyData.id ? this.update() : this.store();
    },
    store: function() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/v1/currency/create",
          this.currencyData
        )
        .then((response) => {
          //console.log(response.data.currency);
          this.currencyData = response.data.currency;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/currency");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    update: function() {
      axios
        .put(
          process.env.VUE_APP_API_URL +
            "/api/v1/currency/update/" +
            this.currencyData.id,
          this.currencyData
        )
        .then((response) => {
          //console.log(response.data.currency);
          this.currencyData = response.data.currency;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/currency");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
  },
};
</script>
