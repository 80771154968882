<template>
  <layout-dashboard>
    <!-- Page Content -->
    <div class="content pt-0">
      <div class="row">
        <div class="col-12">
          <div class="invoice-content">
            <div class="invoice-item">
              <div class="row">
                <div class="col-md-6">
                  <h4>Detalles de la recarga</h4>
                </div>
                <div class="col-md-6">
                  <p class="invoice-details">
                    <strong>Orden:</strong> #{{ orderData.id }} <br />
                    <strong>Fecha:</strong> {{ orderData.date }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Invoice Item -->
            <div class="invoice-item">
              <div class="row">
                <div class="col-md-6">
                  <div class="invoice-info">
                    <strong class="customer-text">Beneficiario</strong>
                    <p
                      v-if="person !== null"
                      class="invoice-details invoice-details-two"
                    >
                      {{ person.user.full_name }} ({{
                        person.user.username
                      }})<br />
                      {{ person.address.address }}, {{ person.address.city
                      }}<br />
                      {{ person.address.state }},
                      {{ person.address.country.name }} <br /><br />
                      Email: {{ person.user.email }}
                    </p>
                    <p
                      v-if="company !== null"
                      class="invoice-details invoice-details-two"
                    >
                      {{ company.user.full_name }} ({{
                        company.user.username
                      }})<br />
                      {{ company.address.address }}, {{ company.address.city
                      }}<br />
                      {{ company.address.state }},
                      {{ company.address.country.name }} <br /><br />
                      Email: {{ company.user.email }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="invoice-info">
                    <strong class="customer-text">Método de pago</strong>
                    <p
                      v-if="orderData.recharge_way"
                      class="invoice-details invoice-details-two"
                    >
                      {{ orderData.recharge_way.name }}<br />
                      <span
                        v-for="(field, key) in orderData.metadata"
                        v-bind:key="key"
                      >
                        <label class="font-weight-bold"
                          >{{ field.label }}:</label
                        >
                        {{ field.pivot.value }}<br />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Invoice Item -->

            <!-- Invoice Item -->
            <div class="invoice-item invoice-table-wrap">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table
                      v-if="orderData.id"
                      class="invoice-table table table-bordered"
                    >
                      <thead>
                        <tr>
                          <th>Descripción</th>
                          <th class="text-center">Moneda</th>
                          <th class="text-right">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Solicitud de recarga</td>
                          <td class="text-center">
                            {{ orderData.currency.acronym }}
                          </td>
                          <td class="text-right">{{ orderData.amount_display.replace('+', '') }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-lg-8 col-xl-7 ml-auto pt-0">
                  <div class="table-responsive">
                    <table class="invoice-table-two table">
                      <tbody>
                        <tr>
                          <th rowspan="2" class="align-middle pt-0">Comisiones</th>
                          <th class="pt-0">Método de Pago:</th>
                          <td class="pt-0"><span><span v-if="orderData.recharge_way_commission > 0">-</span>{{ formatCurrency(orderData.recharge_way_commission) }}</span></td>
                        </tr>
                        <tr>
                          <th>PagosUnDolar:</th>
                          <td><span><span v-if="orderData.system_commission > 0">-</span>{{ formatCurrency(orderData.system_commission) }}</span></td>
                        </tr>
                        <tr>
                          <th></th>
                          <th class="py-5">Monto a depositar:</th>
                          <td class="py-5"><span>{{ orderData.amount_transaction_display.replace('+', '') }}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Invoice Item -->

            <!-- Invoice Information -->
            <div class="other-info">
              <div class="form-group mr-2">
                <label class="col-form-label mr-2 font-weight-bold"
                  >Estado:</label
                >
                <span class="mr-2">{{ orderData.status.display_name }}</span>
                <button
                  v-if="orderData.status.name == 'pending'"
                  type="button"
                  @click="complete"
                  class="btn btn-primary mr-sm-1"
                >
                  <i class="fas fa-check"></i> Finalizar
                </button>
                <button
                  v-if="orderData.status.name == 'pending'"
                  type="button"
                  class="btn btn-danger mr-sm-1"
                  data-toggle="modal"
                  data-target="#modalCancel"
                >
                  <i class="fas fa-times"></i> Rechazar
                </button>
                <button
                  v-if="orderData.status.name == 'canceled'"
                  type="button"
                  @click="setToPending"
                  class="btn btn-warning mr-sm-1"
                >
                  <i class="fas fa-undo"></i> Volver a procesar
                </button>
              </div>
            </div>
            <!-- /Invoice Information -->

            <!-- Modal Deny -->
            <div
              class="modal fade deny"
              id="modalCancel"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modalCancelLabel"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div class="modal-content">
                  <div class="modal-header border-0 pb-0">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i class="far fa-times-circle"></i>
                      </span>
                    </button>
                  </div>
                  <form
                    @submit.prevent="cancel"
                    method="POST"
                  >
                    <div class="modal-body">
                      <label class="required"
                        >Por favor, entre una razon para rechazar la orden.</label
                      >
                      <textarea
                        class="form-control"
                        rows="8"
                        v-model="deny.reason"
                      ></textarea>
                      <span class="text-danger" v-if="errors.reason">{{
                        errors.reason[0]
                      }}</span>
                    </div>
                    <div class="modal-footer border-0 justify-content-end">
                      <button type="submit" class="btn btn-danger">
                        <i class="fas fa-times"></i> Rechazar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- /Modal Deny -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {
      orderData: {
        id: "",
        date: "",
        status: "",
        total_amount: '0.00'
      },
      deny: {
        reason: ''
      },
      person: null,
      company: null,
      errors: [],
    };
  },
  mixins: [global],
  beforeMount() {
    axios
      .get(
        process.env.VUE_APP_API_URL +
          "/api/v1/requests/recharge/find/" +
          this.$route.params.id
      )
      .then((response) => {
        //console.log(response.data.recharge_request);
        this.orderData = response.data.recharge_request;
        this.orderData.total_amount = parseFloat(this.orderData.amount) - parseFloat(this.orderData.system_commission) - parseFloat(this.orderData.recharge_way_commission);
        //console.log(response.data.message);
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];

        EventBus.$emit("showErrors", {
          msg: error.response.data.error,
        });

        console.log(error.response.data.error);
      })
      .finally(() => {
        if (this.orderData.person_id) {
          this.setPerson();
        } else if (this.orderData.company_id) {
          this.setCompany();
        }
      });
  },
  mounted() {},
  methods: {
    setPerson() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/profiles/person/get/" +
            this.orderData.person_id
        )
        .then((response) => {
          this.person = response.data.person;
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    setCompany() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/profiles/company/get/" +
            this.orderData.company_id
        )
        .then((response) => {
          this.company = response.data.company;
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    complete() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/requests/recharge/complete/" +
            this.orderData.id
        )
        .then((response) => {
          //There is no endoint to get status
          this.orderData.status.name = 'completed';
          this.orderData.status.display_name = 'Completado';

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/orders");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    cancel() {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/api/v1/requests/recharge/cancel/" +
            this.orderData.id,
            this.deny
        )
        .then((response) => {
          //There is no endoint to get status
          this.orderData.status.name = 'canceled';
          this.orderData.status.display_name = 'Cancelado';

          $('#modalCancel').modal('hide');
          
          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/orders");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    setToPending() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/requests/recharge/set-to-pending/" +
            this.orderData.id
        )
        .then((response) => {
          //There is no endoint to get status
          this.orderData.status.name = 'pending';
          this.orderData.status.display_name = 'Pendiente';

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/orders");

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
  },
};
</script>
