var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10 offset-md-1"},[_c('div',{staticClass:"account-content"},[_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-md-12 col-lg-6 mx-auto"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10 mt-5 mb-0"},[_c('h2',[_vm._v("Confirmar Email")]),_c('p',[_vm._v("Por favor, rellene el siguiente formulario para confirmar su correo electrónico.")])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row form-row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Correo electrónico "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"vid":"email","name":"Correo electrónico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"lower-case",rawName:"v-lower-case"}],attrs:{"solo":"","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Código de verificación "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"vid":"email_verification_code","name":"Código de verificación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.verification_code),callback:function ($$v) {_vm.verification_code=$$v},expression:"verification_code"}})]}}],null,true)})],1)])]),_c('v-row',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Contraseña")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"vid":"password","name":"Contraseña","rules":_vm.applyPasswordRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"error-messages":errors,"counter":"","solo":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)])]),_c('v-row',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Confirmar contraseña")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('ValidationProvider',{attrs:{"vid":"password_confirmation","name":"Password confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPasswordConfirmation ? 'text' : 'password',"error-messages":errors,"solo":""},on:{"click:append":function($event){_vm.showPasswordConfirmation = !_vm.showPasswordConfirmation}},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}})]}}],null,true)})],1)])])],1)]),_c('div',{staticClass:"submit-section submit-btn-bottom mt-3 mb-5"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Confirmar")])])])]}}])})],1)])])])])]),_c('loading-progress',{attrs:{"show":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }