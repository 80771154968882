<template>
  <div>
    <table
      id="table-companies"
      class="table table-hover table-center responsive w-100 mb-0"
    >
      <thead>
        <tr>
          <th>Correo electrónico</th>
          <th>Usuario</th>
          <th>Razón Social</th>
          <th>Saldo</th>
          <th>Estado</th>
          <th class="no-sort"></th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";

export default {
  data() {
    return {
      companies: [],
      dataTable: null,
      tableFields: [
        "user.email",
        "user.username",
        "social_reason",
        "wallet.amount:formatCurrency",
        "id:badgeStatus",
      ],
      errors: [],
    };
  },
  mixins: [global, table],
  beforeMount() {},
  mounted() {
    var options = {
      order: [[4, "desc"]],
      columnDefs: [
        {
          responsivePriority: 1,
          targets: 0,
        },
        { targets: 3, responsivePriority: 4, className: "dt-body-right" },
        { targets: 4, responsivePriority: 3 },
        {
          responsivePriority: 2,
          orderable: false,
          targets: -1,
        },
      ],
    };

    this.dataTable = this.initializeTable("#table-companies", options);

    axios
      .post(process.env.VUE_APP_API_URL + "/api/v1/profiles/company/list")
      .then((response) => {
        this.companies = response.data.companies;
        //console.log(response.data.companies);
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];

        EventBus.$emit("showErrors", {
          msg: error.response.data.error,
        });

        console.log(error.response.data.error);
      })
      .finally(() => {
        this.generateTableWithData(this.companies, this.tableFields);

        //this.renderTable();

        var _self = this;

        $('a[data-toggle="tab"]').on("shown.bs.tab", function() {
          _self.renderTable();
        });

        console.log("Component mounted.");
      });
  },
  methods: {
    badgeStatus: function(id) {
      let company = this._clone(
        this.companies.filter(function(c) {
          return c.id == id;
        })[0]
      );

      if (company != undefined && company != "") {
        let html = '<span class="badge light badge-';

        if (company.user.active && company.status.active) {
          const statuses = {
            approved: "success",
            pending: "warning",
            denied: "danger",
            on_hold: "secondary",
          };

          html += statuses[company.status.name] ?? '';

          html += '">' + company.status.display_name + "</span>";
        } else {
          html += 'dark">Desactivado</span>';
        }

        return html;
      }

      return '';
    },
    tableActionsByObject(company) {
        let actions = '<div class="table-action">';
        if (company.user.can_be_edited) {
          actions +=
              `<a href="` +
              this.$router.resolve({
                name: "client-company-edit",
                params: {id: company.id},
              }).href +
              `" class="btn btn-sm bg-warning-light">
                        <i class="far fa-edit"></i> Editar
                      </a>`;
        }

        actions +=
            `<a href="` +
            this.$router.resolve({
              name: "client-transactions-view",
              params: {id: company.user.id},
            }).href +
            `" class="btn btn-sm bg-info-light">
                <i class="far fa-eye"></i> Transacciones
                </a>`;

        actions += "</div>";

        return actions;
    },
  },
};
</script>
