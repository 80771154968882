<template>
  <v-row>
    <div class="col-md-12">
      <v-row>
        <div class="col-md-10">
          <h4 class="mb-4">
            <span>{{ headerText }}</span> banner
          </h4>
        </div>
      </v-row>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" @keypress="onChange">
          <v-card elevation="1">
            <v-card-text class="card-body">
              <v-row class="form-row">
                <v-col cols="6">
                  <div class="form-group">
                    <label>Título</label>
                    <span class="text-danger">*</span>
                    <ValidationProvider
                        vid="title"
                        name="Título"
                        rules="required"
                        v-slot="{ errors }"
                    >
                      <v-text-field
                          v-model="bannerDataForm.title"
                          :error-messages="errors"
                          solo
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Banner</label>
                    <span class="text-danger">*</span>
                    <ValidationProvider
                        vid="image"
                        :rules="bannerProviderRule"
                        name="Banner"
                        v-slot="{ errors }"
                    >
                      <v-file-input
                          @change="onUploadBannerImage"
                          @click:clear="onClearBannerImage"
                          v-model="bannerDataForm.image_file"
                          prepend-icon="mdi-camera"
                          :accept="allowedMimeTypesAsString"
                          :rules="bannerRules"
                          :error="errors.length > 0"
                          :error-messages="errors"
                          dense
                          show-size
                          small-chips
                      >
                      </v-file-input>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <div>
                      <v-checkbox
                          v-model="bannerDataForm.active"
                      >
                        <template v-slot:label>
                          <div class="mt-2">Activar al adicionar</div>
                        </template>
                      </v-checkbox>
                    </div>
                  </div>
                </v-col>

                <v-col cols="6" class="pr-5 pl-5">
                  <v-card
                      :loading="loadingImage"
                      :elevation="cardImageElevation"
                  >
                    <v-img
                        :src="bannerDataForm.image"
                        :aspect-ratio="16/9"
                        alt="Banner Image"
                        max-height="200"
                        align="center"
                    ></v-img>
                    <v-card-subtitle align="center">
                      <v-row>
                        <v-col
                            cols="12"
                            lg="6"
                            class="mt-md-1 pl-md-3 text-left"
                        >
                          {{ bannerName }}
                        </v-col>
                      </v-row>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>

          </v-card>
          <div class="submit-section submit-btn-bottom mt-8">
            <button
                type="submit"
                class="btn btn-primary"
            >
              Guardar
            </button>
            <button
                class="btn btn-secondary ml-1"
                @click="onCancel()"
            >
              Cancelar
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <loading-progress :show="loading"></loading-progress>
  </v-row>
</template>

<script>
import global from "@/helpers/global";
import image from "@/helpers/image";
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import LoadingProgress from '../Shared/LoadingProgress';

export default {
  name: "BannerForm",
  components: { LoadingProgress, ValidationObserver, ValidationProvider },
  mixins: [global, image],
  props: {
    bannerData: {
      type: Object,
      default: () => ({
        title: '',
        name: '',
        image: null,
        slug: null,
        image_file: null,
        active: false,
      })
    }
  },
  data: () => ({
    loadingImage: false,
    cardImageElevation: 1,
    bannerDataForm: {},
    currentImage: null,
    bannerRules: []
  }),
  methods: {
    onSubmit() {
      this.startLoading();

      if (this.isEditForm && (_.isNull(this.bannerDataForm.image_file) || _.isUndefined(this.bannerDataForm.image_file))) {
        delete this.bannerDataForm.name;
        delete this.bannerDataForm.image;
      }

      this.$emit('submit');

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    onCancel() {
      this.$refs.form.reset();
      this.clear();

      this.$router.push({ name: 'banners' });
    },
    onChange() {
      this.$emit('update:bannerData', this.bannerDataForm);
    },
    clear() {
      this.bannerDataForm = {
        title: '',
        name: '',
        image: null,
        image_file: null,
        active: false,
      };
      this.$emit('update:bannerData', this.bannerDataForm);
    },
    setErrors(errors) {
      this.$refs.form.setErrors(errors);

      setTimeout(() => {
        this.stopLoading();
      }, 100);
    },
    onUploadBannerImage(image) {
      this.loadingImage = true;
      this.bannerDataForm.active = false;
      if (!image || image.size > process.env.VUE_APP_IMAGE_FILE_MAX_SIZE) {
        this.loadingImage = false;
        this.cardImageElevation = 1;
        this.bannerDataForm.image = this.isEditForm ? this.currentImage : null;
        this.$emit('update:bannerData', this.bannerDataForm);
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        this.bannerDataForm.image = reader.result;
        this.cardImageElevation = 20;
        this.loadingImage = false;
        this.$emit('update:bannerData', this.bannerDataForm);
      };
    },
    onClearBannerImage() {
      if (this.isEditForm) {
        this.bannerDataForm.image = this.currentImage;
      }
    }
  },
  computed: {
    isEditForm() {
      return !!_.get(this.bannerData, 'id', false);
    },
    headerText() {
      return this.isEditForm ? 'Editar' : 'Adicionar';
    },
    bannerName() {
      if (this.isEditForm) {
        return this.bannerDataForm.name;
      }

      return this.bannerDataForm.image_file ? this.bannerDataForm.image_file.name : 'No ha seleccionado un banner';
    },
    bannerProviderRule() {
      return this.isEditForm && (_.isNull(this.bannerDataForm.image_file) || _.isUndefined(this.bannerDataForm.image_file)) ? '' : 'required';
    }
  },
  mounted() {
    this.bannerRules = [
      this.maxSizeRule(),
      this.mimeTypeRule(),
    ];
  },
  watch: {
    bannerData(newValue) {
      if (newValue.id) {
        this.bannerDataForm = this.bannerData;
      }
    },
    'bannerDataForm.image_file': function (val) {
      this.bannerDataForm.name = _.get(val, 'name', '');
    },
    'bannerDataForm.image': function (val) {
      if (val && !this.currentImage) {
        this.currentImage = val;
      }
    }
  }
};
</script>
