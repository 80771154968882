<template>
  <div>
    <form @submit.prevent="update" method="POST" class="p-5 col-sm-10 mx-auto">
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Tipo de comisión</label>
        <div class="col-md-8">
          <select
            v-model="transferData.commission_type_id"
            class="custom-select"
          >
            <option
              v-for="(value, key) in commission_types"
              v-bind:key="key"
              :value="value.id"
              >{{ value.display_name }}</option
            >
          </select>
          <small v-if="errors.commission_type_id" class="text-danger">{{
            errors.commission_type_id[0]
          }}</small>
          <small v-else
            >Selecciona el tipo de comisión si porcentaje o fijo</small
          >
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-4 col-form-label">Monto de la comisión</label>
        <div class="col-md-8">
          <input
            v-model="transferData.value"
            type="number"
            class="form-control"
            min="0"
            step="any"
          />
          <small v-if="errors.value" class="text-danger">{{
            errors.value[0]
          }}</small>
          <small v-else>Introduce la cantidad total a cobrar</small>
        </div>
      </div>

      <div class="submit-section">
        <button type="submit" class="btn btn-primary">
          Actualizar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";

export default {
  data() {
    return {
      transferData: {
        commission_type_id: "",
        value: 0,
      },
      commission_types: [],
      errors: [],
    };
  },
  mixins: [global],
  beforeMount() {
    this.getCommissionTypes();

    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/commission/get/transfer")
      .then((response) => {
        //console.log(response.data.commission);
        this.transferData = response.data.commission;
        //console.log(response.data.message);
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];

        EventBus.$emit("showErrors", {
          msg: error.response.data.error,
        });

        console.log(error.response.data.error);
      });
  },
  mounted() {},
  methods: {
    update: function() {
      this.errors = [];
      axios
        .put(
          process.env.VUE_APP_API_URL + "/api/v1/commission/update/transfer",
          this.transferData
        )
        .then((response) => {
          //console.log(response.data.commission);
          this.transferData = response.data.commission;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
  },
};
</script>
