<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="" method="POST">
          <div class="row">
            <div class="col-md-10">
              <h4 class="mb-4">
                <span>Adicionar</span> recarga
              </h4>
            </div>
          </div>

          <!-- Recharge Method -->
          <div class="card">
            <div class="card-body">
              <div class="row form-row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label>Nombre</label>
                    <span class="text-danger">*</span>
                    <input
                        v-model="user.full_name"
                        type="text"
                        class="form-control"
                        disabled
                    />
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Tipo</label>
                    <span class="text-danger">*</span>
                    <v-radio-group
                        v-model="transactionType"
                        inline
                    >
                      <v-radio
                          label="Recarga"
                          value="recharge"
                      ></v-radio>
                      <v-radio
                          label="Retiro"
                          value="withdrawal"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Monto</label>
                    <span class="text-danger">*</span>
                    <input
                        v-model="rechargeData.amount"
                        type="number"
                        class="form-control"
                        min="0"
                        step="any"
                    />
                    <small v-if="errors.amount" class="text-danger">{{
                        errors.amount[0]
                      }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Descripción</label>
                    <textarea
                      v-model="rechargeData.description"
                      class="form-control"
                    />
                    <small v-if="errors.description" class="text-danger">{{
                      errors.description[0]
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Recharge Method -->

          <div class="submit-section submit-btn-bottom">
            <button
                type="button"
                class="btn btn-primary"
                @click="saveTransaction()">
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-secondary ml-1"
              @click="routeToTransactions()"
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import axios from "axios";

export default {
  data() {
    return {
      rechargeData: {
        description: "",
        wallet_id: "",
        amount: null,
      },
      transactionType: 'recharge',
      user: null,
      errors: []
    };
  },
  mixins: [global],
  beforeMount() {
    this.getCommissionTypes();

    if (this.$route.params.id) {
      axios
          .get(process.env.VUE_APP_API_URL
              + "/api/v1/user/get/"
              + this.$route.params.id)
          .then((response) => {
            this.user = response.data.user;
            this.rechargeData.wallet_id = this.user.wallet.id;
          })
          .catch((error) => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          })
          .finally(() => {
            console.log("User loaded");
          });
    }
  },
  mounted() {},
  methods: {
    routeToTransactions: function() {
      this.$router.push({name: 'client-transactions-view', params: { id: this.user.id } });
    },
    saveTransaction: function() {
      if (this.transactionType === 'recharge')
        this.saveRecharge();
      else
        this.saveWithdrawal();
    },
    saveRecharge: function() {
      axios
          .post(
              process.env.VUE_APP_API_URL + "/api/v1/transactions/recharge-by-admin",
              this.rechargeData
          )
          .then((response) => {

            EventBus.$emit("showInfo", {
              msg: response.data.message,
            });

            this.redirectWithDelay("/clients/transactions/view/" + this.user.id);

            //console.log(response.data.message);
          })
          .catch((error) => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          });
    },
    saveWithdrawal: function() {
      axios
          .post(
              process.env.VUE_APP_API_URL + "/api/v1/transactions/withdrawal-by-admin",
              this.rechargeData
          )
          .then((response) => {

            EventBus.$emit("showInfo", {
              msg: response.data.message,
            });

            this.redirectWithDelay("/clients/transactions/view/" + this.user.id);

            //console.log(response.data.message);
          })
          .catch((error) => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          });
    }
  },
};
</script>
