<template>
  <ul>
    <li :class="currentPath.includes('order') || currentPath.includes('home') ? 'active' : 'notactive'">
      <router-link to="/orders">
        <i class="fas fa-file-invoice"></i>
        <span>Órdenes de recarga</span>
      </router-link>
    </li>
    <li :class="currentPath.includes('withdrawal') && !currentPath.includes('withdrawal-methods') ? 'active' : 'notactive'">
      <router-link to="/withdrawal">
        <i class="fas fa-credit-card"></i>
        <span>Retiros de saldo</span>
      </router-link>
    </li>
    <li :class="currentPath.includes('recharge-methods') ? 'active' : 'notactive'">
      <router-link to="/recharge-methods">
        <i class="fas fa-money-bill-alt"></i>
        <span>Métodos de recarga</span>
      </router-link>
    </li>
    <li :class="currentPath.includes('withdrawal-methods') ? 'active' : 'notactive'">
      <router-link to="/withdrawal-methods">
        <i class="fas fa-university"></i>
        <span>Métodos de retiro</span>
      </router-link>
    </li>
    <li :class="currentPath.includes('commissions') ? 'active' : 'notactive'">
      <router-link to="/commissions">
        <i class="fas fa-percentage"></i>
        <span>Comisiones</span>
      </router-link>
    </li>
    <li :class="currentPath.includes('currency') ? 'active' : 'notactive'">
      <router-link to="/currency">
        <i class="fas fa-dollar-sign"></i>
        <span>Monedas</span>
      </router-link>
    </li>
    <li :class="currentPath.includes('exchange-rate') ? 'active' : 'notactive'">
      <router-link to="/exchange-rate">
        <i class="fas fa-chart-line"></i>
        <span>Tasa del día</span>
      </router-link>
    </li>
    <li :class="currentPath.includes('banners') ? 'active' : 'notactive'">
      <router-link :to="{name: 'banners'}">
        <i class="fas fa-bullhorn"></i>
        <span>Banner de anuncios</span>
      </router-link>
    </li>

    <li :class="currentPath.includes('clients') ? 'active' : 'notactive'">
      <router-link to="/clients">
        <i class="fas fa-user-tie"></i>
        <span>Clientes</span>
      </router-link>
    </li>

    <li v-show="curUser.is_super_administrator || false"
        :class="currentPath.includes('users') ? 'active' : 'notactive'"
    >
      <router-link to="/users">
        <i class="fas fa-users"></i>
        <span>Usuarios</span>
      </router-link>
    </li>
    <!--
    <li :class="currentPath.includes('profile') ? 'active' : 'notactive'">
      <router-link to="/profile">
        <i class="fas fa-user-cog"></i>
        <span>Perfil de usuario</span>
      </router-link>
    </li>
    <li :class="currentPath.includes('change-password') ? 'active' : 'notactive'">
      <router-link to="/change-password">
        <i class="fas fa-lock"></i>
        <span>Cambiar contraseña</span>
      </router-link>
    </li>
    -->
    <li>
      <router-link to="/#">
        <i class="fas fa-sign-out-alt"></i>
        <span @click="logout">Salir</span>
      </router-link>
    </li>
  </ul>
</template>

<style src="@/assets/scss/menu.scss" lang='scss' scoped></style>

<script>
import global from "@/helpers/global";

export default {
  mixins: [global],
  computed: {}
};
</script>
