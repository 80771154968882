<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand p-0">
          <div class="navbar-nav mr-auto">
            <h4 class="mb-0">Transacciones</h4>
            <br/>
            <p v-if="user"> &nbsp; - <span v-text="user.full_name"/> - Saldo: <span v-text="user.wallet.amount_display"/></p>
          </div>
          <div
              class="navbar-nav flex-row-reverse ml-md-auto align-self-md-end pl-1"
          >
            <router-link :to="{name: 'client-transaction-add', params: {id: this.$route.params.id}}"
                         class="btn btn-primary">
              <i class="fas fa-plus"></i>
              <span class="ml-1">Agregar Transacción</span>
            </router-link>
          </div>
        </nav>
      </div>
      <div class="col-md-12">
        <div class="recharge-tab">
          <div class="tab-content">
            <!-- Recharge Tab -->
            <div class="tab-pane show active">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <div class>
                    <table
                        id="table-transactions"
                        class="table table-hover table-center responsive w-100 mb-0"
                    >
                      <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Tipo de Transacción</th>
                        <th>Estado</th>
                        <th>Monto</th>
                        <!--                        <th>Comisiones</th>-->
                        <th>Remitente</th>
                        <th>Destinatario</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Recharge Tab -->
          </div>
        </div>
      </div>
    </div>

    <!-- Popup modal confirm delete transaction -->
    <div class="text-center">
      <confirm-dialog
          ref="confirmDeleteTransactionDialog"
          header_title="Eliminar Transacción"
          @accept="onAcceptConfirmDeleteTransaction"
      ></confirm-dialog>
    </div>
  </layout-dashboard>
</template>

<script>
import {EventBus} from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";
import axios from "axios";
import ConfirmDialog from "../Shared/ConfirmDialog";

export default {
  data() {
    return {
      transactions: [],
      user: null,
      dataTable: null,
      datatableOptions: {
        columnDefs: [
          {orderable: true, targets: 0, type: 'date'},
          {orderable: true, targets: 1},
          {orderable: true, targets: 2},
          {orderable: false, targets: 3},
          {orderable: false, targets: 4},
          // {orderable: false, targets: 5},
          {orderable: false, targets: 6},
        ],
      },
      tableFields: [
        "date",
        "type",
        "status",
        "amount",
        // "commissions",
        "user_from",
        "user_to",
      ],
      errors: [],
    };
  },
  mixins: [global, table],
  components: {ConfirmDialog},
  mounted() {

    this.dataTable = this.initializeTable("#table-transactions", this.datatableOptions);

    this.getUser();

    this.buildTransactionDatatable();

    this.deleteAction();
  },
  methods: {
    badgeStatus: function () {

    },
    tableActionsByObject(transaction) {
      let actions = {htmlText: '<div class="table-action">'};

      if (transaction.by_admin == true || transaction.by_admin === '1') {
        this.generateDeleteAction(transaction.id, actions);
      }

      return actions.htmlText;
    },
    deleteAction() {
      const component = this;

      $('#table-transactions').on("click", ".action-delete", function () {
        $("#table-transactions tr.selected").removeClass("selected");
        $(this)
            .closest("tr")
            .addClass("selected");

        component.setDataIdSelected($(this).attr("attr_id"));
        component.$refs.confirmDeleteTransactionDialog.open();
      });
    },
    async buildTransactionDatatable() {
      axios
          .get(process.env.VUE_APP_API_URL
              + "/api/v1/transactions/by-user/"
              + this.$route.params.id)
          .then((response) => {
            this.transactions = response.data.transactions;
          })
          .catch((error) => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          })
          .finally(() => {
            this.generateTableWithData(this.transactions, this.tableFields);

            console.log("Transactions loaded.");
          });
    },
    async deleteTransaction() {

      const deleteTransactionUrl = `${process.env.VUE_APP_API_URL}/api/v1/transactions/delete/${this.dataIdSelected}`;

      try {
        const { data: { message: message } } = await axios.delete(deleteTransactionUrl);

        await this.buildTransactionDatatable();

        await EventBus.$emit('showInfo', { msg: message });
      } catch ({ response: { data } }) {

        EventBus.$emit('showErrors', {
          msg: _.get(data, 'message', data.error || '')
        });
      }
    },
    async onAcceptConfirmDeleteTransaction() {
      await this.deleteTransaction();
    },
    async getUser() {
      axios
          .get(process.env.VUE_APP_API_URL
              + "/api/v1/user/get/"
              + this.$route.params.id)
          .then((response) => {
            this.user = response.data.user;
          })
          .catch((error) => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          })
          .finally(() => {
            console.log("User loaded");
          });
    }
  },
};
</script>
