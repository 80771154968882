<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="update" method="POST">
          <div class="row">
            <div class="col-md-12">
              <h4 class="mb-4">
                Perfil de Persona
                <div
                  v-if="userData.status && userData.status.name == 'approved'"
                  class="flex-row-reverse float-right ml-md-auto align-self-md-end pl-1"
                >
                  <a
                    v-if="userData.user.active"
                    href="javascript:void(0);"
                    :attr_id="userData.user.id"
                    class="btn btn-sm bg-danger-light action-disable"
                  >
                    <i class="fas fa-times"></i> Desactivar
                  </a>
                  <a
                    v-else
                    href="javascript:void(0);"
                    :attr_id="userData.user.id"
                    class="btn btn-sm bg-success-light action-enable"
                  >
                    <i class="fas fa-check"></i> Activar
                  </a>
                </div>
              </h4>
            </div>
          </div>

          <!-- About Me -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                Datos de usuario
                <span
                  v-if="userData.user && userData.user.active"
                  class="badge light badge-success text-xs"
                  >Activo</span
                >
                <span v-else class="badge light badge-dark text-xs"
                  >Desactivado</span
                >
              </h4>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Usuario</label>
                    <input
                      v-lower-case
                      v-model="userData.user.username"
                      type="text"
                      class="form-control"
                      readonly
                    />
                    <small v-if="errors.username" class="text-danger">{{
                      errors.username[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      v-lower-case
                      v-model="userData.user.email"
                      type="email"
                      class="form-control"
                      readonly
                    />
                    <small v-if="errors.email" class="text-danger">{{
                      errors.email[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Nombre</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="userData.user.names"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.names" class="text-danger">{{
                      errors.names[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Apellido</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="userData.user.surnames"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.surnames" class="text-danger">{{
                      errors.surnames[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Número de identificación</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="userData.document"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.document" class="text-danger">{{
                      errors.document[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Teléfono</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="userData.phone"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.phone" class="text-danger">{{
                      errors.phone[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label>
                      Foto de Documento de Identidad
                      <span class="text-danger">*</span>
                    </label>
                    <div class="change-avatar">
                      <div class="profile-img">
                        <a
                          download="foto-identidad"
                          :href="userData.document_image"
                        >
                          <img
                              v-if="userData.document_image"
                              :src="userData.document_image"
                              alt="Documento de Identidad"
                          />
                          <img
                              v-else
                              src="@/assets/img/document.jpeg"
                              alt="User Image"
                          />
                        </a>
                      </div>
                      <div class="upload-img">
                        <div class="change-photo-btn">
                          <span> <i class="fa fa-upload"></i> Subir Foto </span>
                          <v-file-input
                            ref="fileInputDocumentImage"
                            @click:clear="clearDocumentImage"
                            @change="uploadDocumentImage"
                            class="upload"
                            dense
                            show-size
                            small-chips
                            accept="image/png, image/jpeg"
                            :rules="fileRules"
                            label="Subir Foto"
                          ></v-file-input>
                        </div>
                        <small class="form-text text-muted"
                          >Permitido JPG o PNG.</small
                        >
                      </div>
                    </div>
                    <small v-if="errors.document_image" class="text-danger">{{
                      errors.document_image[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label>
                      Foto de Perfil
                      <span class="text-danger">*</span>
                    </label>
                    <div class="change-avatar">
                      <div class="profile-img">
                        <a
                          download="foto-perfil"
                          :href="userData.avatar"
                        >
                          <img
                              v-if="userData.avatar"
                              :src="userData.avatar"
                              alt="Foto de Perfil"
                          />
                          <img
                              v-else
                              src="@/assets/img/profile.jpeg"
                              alt="User Image"
                          />
                        </a>
                      </div>
                      <div class="upload-img">
                        <div class="change-photo-btn">
                          <span> <i class="fa fa-upload"></i> Subir Foto </span>
                          <v-file-input
                            dense
                            show-size
                            small-chips
                            ref="fileInputAvatar"
                            label="Subir Foto"
                            class="upload"
                            accept="image/png, image/jpeg"
                            :rules="fileRules"
                            @click:clear="clearAvatar"
                            @change="uploadAvatarImage"
                          ></v-file-input>
                        </div>
                        <small class="form-text text-muted"
                          >Permitido JPG o PNG.</small
                        >
                      </div>
                    </div>
                    <small v-if="errors.avatar" class="text-danger">{{
                      errors.avatar[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Estado</label>
                    <span class="text-danger">*</span>
                    <v-autocomplete
                      class="form-control"
                      :items="ClientStatuses"
                      flat
                      hide-details
                      hide-no-data
                      solo-inverted
                      item-text="display_name"
                      item-value="id"
                      v-model="userData.status_id"
                    ></v-autocomplete>
                    <small v-if="errors.status_id" class="text-danger">{{
                      errors.status_id[0]
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /About Me -->

          <!-- Contact Details -->
          <div class="card contact-card">
            <div class="card-body">
              <h4 class="card-title">Datos de contacto</h4>
              <div class="row form-row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Dirección</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="userData.address.address"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.address" class="text-danger">{{
                      errors.address[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Ciudad</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="userData.address.city"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.city" class="text-danger">{{
                      errors.city[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Estado / Provincia</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="userData.address.state"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.state" class="text-danger">{{
                      errors.state[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">País</label>
                    <span class="text-danger">*</span>
                    <select
                      v-model="userData.address.country_id"
                      class="custom-select"
                    >
                      <option
                        v-for="(value, key) in countries"
                        v-bind:key="key"
                        :value="value.id"
                        >{{ value.name }}</option
                      >
                    </select>
                    <small v-if="errors.country_id" class="text-danger">{{
                      errors.country_id[0]
                    }}</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Código Postal</label>
                    <span class="text-danger">*</span>
                    <input
                      v-model="userData.address.zip"
                      type="text"
                      class="form-control"
                    />
                    <small v-if="errors.zip" class="text-danger">{{
                      errors.zip[0]
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Contact Details -->

          <div class="submit-section submit-btn-bottom">
            <button type="submit" class="btn btn-primary">
              Actualizar
            </button>
            <button
              type="button"
              class="btn btn-secondary ml-1"
              @click="routeToClients()"
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import image from '../../helpers/image';

export default {
  data() {
    return {
      userData: {
        document: "",
        phone: "",
        user: {
          active: 0,
          names: "",
          surnames: "",
          username: "",
          email: "",
          password: "",
          password_confirmation: "",
        },
        address: {
          address: "",
          city: "",
          state: "",
          zip: "",
          country_id: "",
        },
        document_image: "",
        avatar: "",
        id: null,
        document_name: null,
        avatar_name: null,
      },
      fileRules: [],
      countries: [],
      client_statuses: [],
      errors: [],
      avatar_url: '/api/v1/clients/person/avatar/',
      document_url: '/api/v1/clients/person/document/',
      avatar: '',
      document: '',
      currentDocumentImage: null,
      loadedDocumentImage: false,
      isDirtyDocumentImage: false,
      currentAvatar: null,
      loadedAvatar: false,
      isDirtyAvatar: false,
    };
  },
  mixins: [global, image],
  beforeMount() {
    this.getCountries();
    this.getPerson();
  },
  mounted() {
    this.fileRules = [
      this.maxSizeRule(),
      this.mimeTypeRule(),
    ];
  },
  methods: {
    routeToClients: function() {
      this.$router.push({ name: 'clients' });
    },
    getPerson: function() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/profiles/person/get/" +
            this.$route.params.id
        )
        .then((response) => {
          if (
            response.data.person.user.username == null ||
            response.data.person.user.username == ""
          ) {
            this.$router.push("/clients");
          }
          response.data.person.document_image = response.data.person.avatar =
            "";
          this.userData = response.data.person;

          this.getDocumentImage();
          this.getAvatarImage();
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        })
        .finally(() => {
          var _self = this;

          $("form").on("click", ".action-enable", function() {
            _self.enable(_self.$route.params.id);
          });

          $("form").on("click", ".action-disable", function() {
            _self.disable(_self.$route.params.id);
          });
        });
    },
    getDocumentImage: function() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/clients/person/document/" +
            this.$route.params.id,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          if (response.data.byteLength > 0) {
            this.userData.document_image =
              "data:" +
              response.headers["content-type"] +
              ";base64," +
              Buffer.from(response.data, "binary").toString("base64");
          }
        })
        .catch(() => {});
    },
    getAvatarImage: function() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/clients/person/avatar/" +
            this.$route.params.id,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          if (response.data.byteLength > 0) {
            this.userData.avatar =
              "data:" +
              response.headers["content-type"] +
              ";base64," +
              Buffer.from(response.data, "binary").toString("base64");
          }
        })
        .catch(() => {});
    },
    uploadDocumentImage(file) {
      this.clearDocumentImageErrors()

      if (
          file == null ||
          !file ||
          file.size > process.env.VUE_APP_IMAGE_FILE_MAX_SIZE ||
          !this.checkMimeTypeRule(file.type)
      ) {
        this.loadedDocumentImage = false;
        this.clearDocumentImage()
        return;
      }

      this.errors = [];

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.userData.document_image = reader.result;
        this.loadedDocumentImage = true;
      };
    },
    uploadAvatarImage(file) {
      this.clearAvatarErrors();

      if (
          file == null ||
          !file ||
          file.size > process.env.VUE_APP_IMAGE_FILE_MAX_SIZE ||
          !this.checkMimeTypeRule(file.type)
      ) {
        this.loadedAvatar = false;
        this.clearAvatar();
        return;
      }

      this.errors = [];

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.userData.avatar = reader.result;
        this.loadedAvatar = true;
      };
    },
    enable(id) {
      axios
        .get(
          process.env.VUE_APP_API_URL + "/api/v1/clients/person/enable/" + id
        )
        .then((response) => {
          this.userData.user.active = 1;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    disable(id) {
      axios
        .get(
          process.env.VUE_APP_API_URL + "/api/v1/clients/person/disable/" + id
        )
        .then((response) => {
          this.userData.user.active = 0;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    update: function() {
      this.errors = [];

      if ((!this.loadedDocumentImage || !this.loadedAvatar) &&
          (!this.$refs.fileInputDocumentImage.validate() || !this.$refs.fileInputAvatar.validate())) {
        this.errors = [];

        return;
      }

      let request = { ...this.userData };

      if (!this.loadedDocumentImage && !this.isDirtyDocumentImage) {
        this.$refs.fileInputDocumentImage.resetValidation();

        if (this.hasDocumentImage) {
          delete request.document_image;
        } else {
          request.document_image = '';
        }
      }

      if (!this.loadedAvatar && !this.isDirtyAvatar) {
        this.$refs.fileInputAvatar.resetValidation();

        if (this.hasAvatar) {
          delete request.avatar;
        } else {
          request.avatar = '';
        }
      }

      axios
        .put(
          process.env.VUE_APP_API_URL +
            "/api/v1/clients/person/update-by-admin/" +
            this.$route.params.id,
          request
        )
        .then((response) => {
          this.userData = response.data.person;

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          this.redirectWithDelay("/clients");
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    getAvatar: function (id) {
      let url = process.env.VUE_APP_API_URL + this.avatar_url + id;
      axios
          .get(
              url,
              {responseType: 'arraybuffer'}
          )
          .then((response) => {
            let raw = Buffer.from(response.data).toString('base64');
            this.avatar = "data:" + response.headers["content-type"] + ";base64,"+raw;
          })
          .catch((error) => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          })
          .finally(() => {

          });
    },
    getDocument: function (id) {
      let url = process.env.VUE_APP_API_URL + this.document_url + id;
      axios
          .get(
              url,
              {responseType: 'arraybuffer'}
          )
          .then((response) => {
            let raw = Buffer.from(response.data).toString('base64');
            this.document = "data:" + response.headers["content-type"] + ";base64,"+raw;
          })
          .catch((error) => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);
          })
          .finally(() => {

          });
    },
    clearDocumentImage() {
      this.userData.document_image = this.currentDocumentImage;
      this.clearDocumentImageErrors();
    },
    clearDocumentImageErrors() {
      this.errors.document_image = [];
    },
    clearAvatar() {
      this.userData.avatar = this.currentAvatar;
      this.clearAvatarErrors();
    },
    clearAvatarErrors() {
      this.errors.avatar = [];
    },
  },
  computed: {
    hasDocumentImage() {
      return !!this.userData.document_name;
    },
    hasAvatar() {
      return !!this.userData.avatar_name;
    }
  },
  watch: {
    'userData.document_image': function (val) {
      if (val && !this.currentDocumentImage) {
        this.currentDocumentImage = val;
      }

      if (this.userData.document_name) {
        this.isDirtyDocumentImage = !_.isEqual(val, this.currentDocumentImage);
      }
    },
    'userData.avatar': function (val) {
      if (val && !this.currentAvatar) {
        this.currentAvatar = val;
      }

      if (this.userData.avatar_name) {
        this.isDirtyAvatar = !_.isEqual(val, this.currentAvatar);
      }
    }
  }
};
</script>
