<template>
  <div class="content success-page-cont">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="card success-card">
            <div class="card-body">
              <div class="success-cont">
                <i class="fas fa-check"></i>
                <h3>Email confirmado</h3>
                <p>
                  Gracias por confirmar su correo electrónico.
                </p>
                <router-link
                    :to="{name: 'login'}"
                    class="btn btn-primary view-inv-btn"
                >
                  Entrar
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from "@/helpers/global";

export default {
  mixins: [global],
  mounted() {
    if (this.checkIfLoggedIn()) {
      this.$router.push({ name: 'home' });
    }
  },
};
</script>
