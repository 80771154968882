<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <!-- Tab Content -->
          <div class="account-content">
            <div class="row align-items-center justify-content-center">
              <div class="col-md-12 col-lg-6 mx-auto">
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                      <div class="col-md-10 mt-5 mb-0">
                        <h2>Confirmar Email</h2>
                        <p>Por favor, rellene el siguiente formulario para confirmar su correo electrónico.</p>
                      </div>
                    </div>
                    <!-- Information -->
                    <div class="card">
                      <div class="card-body">
                        <div class="row form-row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>
                                Correo electrónico
                                <span class="text-danger">*</span>
                              </label>
                              <ValidationProvider
                                  vid="email"
                                  name="Correo electrónico"
                                  v-slot="{ errors }"
                              >
                                <v-text-field
                                    solo
                                    v-lower-case
                                    v-model="email"
                                    :error-messages="errors"
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>
                                Código de verificación
                                <span class="text-danger">*</span>
                              </label>
                              <ValidationProvider
                                  vid="email_verification_code"
                                  name="Código de verificación"
                                  v-slot="{ errors }"
                              >
                                <v-text-field
                                    v-model="verification_code"
                                    :error-messages="errors"
                                    solo
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <v-row class="form-row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Contraseña</label>
                              <span class="text-danger">*</span>
                              <ValidationProvider
                                  vid="password"
                                  name="Contraseña"
                                  :rules=applyPasswordRules
                                  v-slot="{ errors }"
                              >
                                <v-text-field
                                    v-model="password"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    :error-messages="errors"
                                    @click:append="showPassword = !showPassword"
                                    counter
                                    solo
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </div>
                          </div>
                        </v-row>
                        <v-row class="form-row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Confirmar contraseña</label>
                              <span class="text-danger">*</span>
                              <ValidationProvider
                                  vid="password_confirmation"
                                  name="Password confirmation"
                                  v-slot="{ errors }"
                              >
                                <v-text-field
                                    v-model="password_confirmation"
                                    :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPasswordConfirmation ? 'text' : 'password'"
                                    :error-messages="errors"
                                    @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                                    solo
                                >
                                </v-text-field>
                              </ValidationProvider>
                            </div>
                          </div>
                        </v-row>
                      </div>
                    </div>
                    <div class="submit-section submit-btn-bottom mt-3 mb-5">
                      <button type="submit" class="btn btn-primary">Confirmar</button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-progress :show="loading"></loading-progress>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, min, confirmed } from 'vee-validate/dist/rules';
import LoadingProgress from '../Shared/LoadingProgress';

extend('required', {
  ...required,
});

extend('min', {
  ...min,
});

extend('confirmed', {
  ...confirmed,
});

export default {
  name: "ConfirmEmail",
  components: { LoadingProgress, ValidationObserver, ValidationProvider },
  mixins: [global],
  data() {
    return {
      email: '',
      verification_code: '',
      password: '',
      password_confirmation: '',
      showPassword: false,
      showPasswordConfirmation: false,
    };
  },
  beforeMount() {
    this.email = this.$route.params.email;
    this.verification_code = this.$route.params.code;
  },
  methods: {
    async onSubmit() {
      try {
        this.startLoading();

        const confirmEmailUrl = `${process.env.VUE_APP_API_URL}/api/v1/security/confirm-email`;

        await axios.post(confirmEmailUrl, {
          email: this.email,
          email_verification_code: this.verification_code,
          password: this.password,
          password_confirmation: this.password_confirmation,
        });

        await this.$router.push({ name: 'email-confirmado' });
      } catch ({ response }) {

        this.$refs.form.setErrors(_.get(response, 'data.errors', {}));

        if (response !== undefined) {
          EventBus.$emit("showErrors", {
            msg: response.data.error
          });
        }
      } finally {
        this.stopLoading();
      }
    },
  },
  computed: {
    applyPasswordRules() {
      const rules = 'min:8|confirmed:password_confirmation';

      if (this.password !== '') {
        return rules;
      }

      return '';
    }
  }
};
</script>
