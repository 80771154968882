<template>
  <layout-dashboard>
    <banner-form
        :bannerData.sync="bannerData"
        ref="createBannerForm"
        @submit="onSubmit"
    ></banner-form>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import BannerForm from './BannerForm';

export default {
  name: "CreateBannerForm",
  components: { BannerForm },
  mixins: [global],
  data: () => ({
    bannerData: {},
  }),
  methods: {
    async store() {
      const storeBannerUrl = `${process.env.VUE_APP_API_URL}/api/v1/banners/store`;

      try {
        const { data: { banner: banner, message } } = await axios.post(storeBannerUrl, this.bannerData);

        this.$refs.createBannerForm.stopLoading();
        this.$refs.createBannerForm.clear()

        if (banner.id && message) {
          EventBus.$emit('showInfo', {
            msg: message
          });

          this.redirectWithDelay({ name: 'banners' });
        }
      } catch ({ response }) {

        const errors = _.get(response, 'data.errors', {});
        this.$refs.createBannerForm.setErrors(errors);

        EventBus.$emit('showErrors', {
          msg: response.data.error
        });
      }
    },
    async onSubmit() {
      await this.store();
    }
  }
};
</script>
