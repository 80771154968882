<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand p-0">
          <div class="navbar-nav mr-auto">
            <h4 class="mb-0">Monedas</h4>
          </div>
          <div class="navbar-nav flex-row-reverse ml-md-auto align-self-md-end pl-1">
            <router-link to="/currency/edit" class="btn btn-primary">
              <i class="fas fa-plus"></i>
              <span class="ml-1">Agregar</span>
            </router-link>
          </div>
        </nav>
      </div>
      <div class="col-md-12">
        <div class="clients-tab">
          <div class="tab-content">
            <!-- Clients Tab -->
            <div class="tab-pane show active">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <div class>
                    <table id="table-currency"
                      class="table table-hover table-center responsive w-100 mb-0"
                    >
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Abreviatura</th>
                          <th>Recarga mínima</th>
                          <th>Recarga máxima</th>
                          <th class="no-sort"></th>
                        </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Clients Tab -->
          </div>
        </div>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";

export default {
  data() {
    return {
      currencies: [],
      dataTable: null,
      tableFields: [
          "name",
          "acronym",
          "min",
          "max"
        ],
      errors: []
    };
  },
  mixins: [global, table],
  beforeMount() {},
  mounted() {
    this.dataTable = this.initializeTable("#table-currency");

    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/currency/list")
      .then(response => {
        this.currencies = response.data.currencies;
      })
      .catch(error => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];
        console.log(error.response.data.message);
      })
      .finally(() => {
        this.generateTableWithData(this.currencies, this.tableFields);

        //this.renderTable();

        var _self = this;

        $("#table-currency").on("click", ".action-enable", function() {
          $("#table-currency tr.selected").removeClass("selected");
          $(this)
            .closest("tr")
            .addClass("selected");

          _self.enable($(this).attr("attr_id"));
        });

        $("#table-currency").on("click", ".action-disable", function() {
          $("#table-currency tr.selected").removeClass("selected");
          $(this)
            .closest("tr")
            .addClass("selected");

          _self.disable($(this).attr("attr_id"));
        });

        console.log("Component mounted.");
      });
  },
  methods: {
    enable(id) {
      axios
        .put(process.env.VUE_APP_API_URL + "/api/v1/currency/enable/" + id)
        .then((response) => {
          let index = this.currencies.findIndex(
            (x) => x.id == id
          );
          
          this.currencies[index].active = 1;

          this.updateRowTable(this.currencies[index]);

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    disable(id) {
      axios
        .put(process.env.VUE_APP_API_URL + "/api/v1/currency/disable/" + id)
        .then((response) => {
          let index = this.currencies.findIndex(
            (x) => x.id == id
          );
          
          this.currencies[index].active = 0;

          this.updateRowTable(this.currencies[index]);

          EventBus.$emit("showInfo", {
            msg: response.data.message,
          });

          console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        });
    },
    tableActions: function(id) {
      let currency = this._clone(
        this.currencies.filter(function(c) {
          return c.id == id;
        })[0]
      );

      let actions = '<div class="table-action">';

      if (currency.active == 0) {
        actions += `
                      <a href="javascript:void(0);" attr_id="` +
          id +
          `" class="btn btn-sm bg-success-light action-enable">
                        <i class="fas fa-check"></i> Activar
                      </a>`;
      } else {
        actions +=
          `
                      <a href="javascript:void(0);" attr_id="` +
          id +
          `" class="btn btn-sm bg-danger-light action-disable">
                        <i class="fas fa-times"></i> Desactivar
                      </a>`;
      }

      actions +=
        `<a href="` +
        this.$router.resolve({
          name: "currency-edit",
          params: { id: id },
        }).href +
        `" class="btn btn-sm bg-warning-light">
                        <i class="far fa-edit"></i> Editar
                      </a>`;

      actions += "</div>";

      return actions;
    },
  }
};
</script>
