<template>
  <layout-dashboard>
    <div class="row">
      <div class="col-md-12">
        <h4 class="mb-4">Órdenes de recarga</h4>
      </div>
      <div class="col-md-12">
        <div class="recharge-tab">
          <div class="tab-content">
            <!-- Recharge Tab -->
            <div class="tab-pane show active">
              <div class="card card-table mb-0">
                <div class="card-body">
                  <div class>
                    <table
                      id="table-recharges"
                      class="table table-hover table-center responsive w-100 mb-0"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Cliente</th>
                          <th>Monto</th>
                          <th>Estado</th>
                          <th>Método de Pago</th>
                          <th>Fecha</th>
                          <th class="no-sort"></th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Recharge Tab -->
          </div>
        </div>
      </div>
    </div>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";

export default {
  data() {
    return {
      recharge_requests: [],
      dataTable: null,
      tableFields: [
        "id",
        "beneficiary.user.full_name",
        "amount:formatCurrency",
        "status:badgeStatus",
        "recharge_way.name",
        "date",
      ],
      clients_person: [],
      clients_company: [],
      errors: [],
    };
  },
  mixins: [global, table],
  beforeMount() {},
  mounted() {
    var options = {
      order: [[0, "desc"]],
      columnDefs: [
        {
          targets: 0,
          className: "dt-center",
        },
        {
          targets: 1,
          responsivePriority: 1,
        },
        {
          targets: 2,
          responsivePriority: 2,
          className: "dt-body-right",
        },
        {
          targets: -1,
          responsivePriority: 3,
          className: "dt-body-right",
        },
        {
          targets: "no-sort",
          orderable: false,
        },
      ],
    };

    this.dataTable = this.initializeTable("#table-recharges", options);

    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/requests/recharge/all")
      .then((response) => {
        this.recharge_requests = response.data.recharge_requests;
        //console.log(response.data.recharge_requests);
      })
      .catch((error) => {
        this.errors = [];
        this.errors = error.response.data.errors ?? [];

        EventBus.$emit("showErrors", {
          msg: error.response.data.error,
        });

        console.log(error.response.data.error);
      })
      .finally(async () => {
        await this.fillBeneficiary();

        this.generateTableWithData(this.recharge_requests, this.tableFields);
        //this.renderTable();

        console.log("Component mounted.");
      });
  },
  methods: {
    async fillBeneficiary() {
      const promises = this.recharge_requests.map(
        async (recharge_request, key) => {
          if (recharge_request.person_id) {
            this.recharge_requests[key].beneficiary = await this.getPerson(
              recharge_request.person_id
            );
          } else if (recharge_request.company_id) {
            this.recharge_requests[key].beneficiary = await this.getCompany(
              recharge_request.company_id
            );
          }
        }
      );
      await Promise.all(promises);
    },
    getPerson(id) {
      if (this.clients_person[id] == undefined) {
        return (this.clients_person[id] = axios
          .get(
            process.env.VUE_APP_API_URL + "/api/v1/profiles/person/get/" + id
          )
          .then((response) => {
            return response.data.person;
          })
          .catch((error) => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            // EventBus.$emit("showErrors", {
            //   msg: error.response.data.error,
            // });

            console.log(error.response.data.error);

            return null;
          }));
      } else {
        return this.clients_person[id];
      }
    },
    getCompany(id) {
      if (this.clients_company[id] == undefined) {
        return (this.clients_company[id] = axios
          .get(
            process.env.VUE_APP_API_URL + "/api/v1/profiles/company/get/" + id
          )
          .then((response) => {
            return response.data.company;
          })
          .catch((error) => {
            this.errors = [];
            this.errors = error.response.data.errors ?? [];

            EventBus.$emit("showErrors", {
              msg: error.response.data.error,
            });

            console.log(error.response.data.error);

            return null;
          }));
      } else {
        return this.clients_company[id];
      }
    },
    badgeStatus: function(status) {
      if (status.name != "") {
        let html = '<span class="badge light badge-';

        switch (status.name) {
          case "completed":
            html += "success";
            break;
          case "pending":
            html += "warning";
            break;
          case "canceled":
            html += "dark";
            break;
          default:
            html += "";
        }

        html += '">' + status.display_name + "</span>";

        return html;
      }

      return status;
    },
    tableActions: function(id) {
      let actions = '<div class="table-action">';

      actions +=
        `<a href="` +
        this.$router.resolve({
          name: "order-view",
          params: { id: id },
        }).href +
        `" class="btn btn-sm bg-info-light"><i class="far fa-eye"></i> Detalles</a>`;

      actions += "</div>";

      return actions;
    },
  },
};
</script>
