<template>
  <v-row align="center" align-content="center" v-show="show">
    <v-overlay z-index="99999" value="true">
      <v-progress-circular
          color="black"
          value="95"
          size="80"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
export default {
  name: "LoadingProgress",
  props: {
    show: {
      type: Boolean,
    },
  }
};
</script>
