<template>
  <layout-dashboard>
    <user-form
        :userData.sync="userData"
        ref="editFormUser"
        @submit="onSubmit"
    ></user-form>
  </layout-dashboard>
</template>

<script>
import { EventBus } from "@/main";
import global from "@/helpers/global";
import UserForm from './UserForm';

export default {
  name: "EditUserForm",
  components: { UserForm },
  mixins: [global],
  data: () => ({
    userData: {},
  }),
  methods: {
    async getUserFromRouteParam() {
      const getUserUrl = `${process.env.VUE_APP_API_URL}/api/v1/user/get/${this.$route.params.id}`;

      const { data: { user } } = await axios.get(getUserUrl);

      this.userData = { ...user };
    },
    async update() {
      const updateUserUrl = `${process.env.VUE_APP_API_URL}/api/v1/user/update/${this.userData.id}`;

      try {
        const { data: { user: user, message } } = await axios.put(updateUserUrl, this.userData);

        this.$refs.editFormUser.stopLoading();
        this.$refs.editFormUser.clear();

        if (user.id && message) {
          EventBus.$emit('showInfo', {
            msg: message
          });

          this.redirectWithDelay({ name: 'users' });
        }
      } catch ({ response }) {

        const errors = _.get(response, 'data.errors', {});
        this.$refs.editFormUser.setErrors(errors);

        EventBus.$emit('showErrors', {
          msg: response.data.message
        });
      }
    },
    async onSubmit() {
      await this.update();
    }
  },
  beforeMount() {
    this.getUserFromRouteParam();
  },
};
</script>
